import { useThree } from "@react-three/fiber";
import { useState, useMemo } from "react";
import * as THREE from "three";

import { useDiscreetTexture } from "../hooks/useDiscreetTexture";
import useIsMobile from "../hooks/useIsMobile";
import { useMatcapTexture } from "../hooks/useMatcapTexture";

const BackLayer = ({
  bounds,
  textureId,
  logoUrl = "/img/frahm_sample.svg",
  campaignLogoUrl = null,
  matCapId,
  color,
}) => {
  const { gl } = useThree();
  const isMobile = useIsMobile();
  const [{ width, height }] = useState(
    isMobile ? { width: 512, height: 512 } : { width: 4096, height: 4096 }
  );

  const textureMap = useDiscreetTexture(textureId, 1, 1);

  const [matcap] = useMatcapTexture(matCapId);

  const {
    canvas,
    logoTexture,
    i,
    campaignCanvas,
    campaignTexture,
    campaignImg,
  } = useMemo(() => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const logoTexture = new THREE.CanvasTexture(canvas);
    logoTexture.wrapS = logoTexture.wrapT = THREE.RepeatWrapping;
    logoTexture.encoding = THREE.sRGBEncoding;

    const i = document.createElement("img");
    i.setAttribute("crossOrigin", "anonymous");

    const campaignCanvas = document.createElement("canvas");
    campaignCanvas.width = width;
    campaignCanvas.height = height;

    const campaignTexture = new THREE.CanvasTexture(campaignCanvas);
    campaignTexture.wrapS = campaignTexture.wrapT = THREE.RepeatWrapping;
    campaignTexture.encoding = THREE.sRGBEncoding;

    const campaignImg = document.createElement("img");
    campaignImg.setAttribute("crossOrigin", "anonymous");

    return {
      canvas,
      logoTexture,
      i,
      campaignCanvas,
      campaignTexture,
      campaignImg,
    };
  }, [width, height]);

  const logoTextureMap = useMemo(() => {
    i.onload = (e) => {
      // @TODO values are hardcoded
      // need to research how to get actual
      // dimensions from an SVG
      let imageWidth = 475;
      let imageHeight = 63;
      const o = e.path ? e.path[0] : e.target;
      if (o.width) {
        imageWidth = o.width;
        imageHeight = o.height;
      }

      let scale = Math.min(width / imageWidth, height / imageHeight);
      let aspectRatio = imageWidth / imageHeight;
      let x = width / 2 - (imageWidth / 2) * scale;
      let y = height / 2 - (imageHeight / 2) * scale;

      let _padding = {
        x: 2 * 50 * aspectRatio,
        y: 2 * 50,
      };

      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, width, height);

      // for testing,
      // it's helpful to see the entire area of the canvas
      // which is visualized as an orange square
      // ctx.fillStyle = "#ff9900";
      // ctx.fillRect(0, 0, width, height);

      ctx.drawImage(
        i,
        x + _padding.x,
        y + _padding.y,
        imageWidth * scale - _padding.x * 2,
        imageHeight * scale - _padding.y * 2
      );

      // box
      // ctx.strokeStyle = "#000000";
      // ctx.lineWidth = 10;
      // ctx.strokeRect(10, 10, 4076, 4076);

      logoTexture.anisotropy = gl.capabilities.getMaxAnisotropy();
      logoTexture.needsUpdate = true;
    };
    i.src = logoUrl;

    return logoTexture;
  }, [logoUrl, canvas, height, width, i, logoTexture, gl.capabilities]);

  const campaignTextureMap = useMemo(() => {
    campaignImg.onload = (e) => {
      // @TODO values are hardcoded
      // need to research how to get actual
      // dimensions from an SVG
      let imageWidth = 475;
      let imageHeight = 63;
      const o = e.path ? e.path[0] : e.target;
      if (o.width) {
        imageWidth = o.width;
        imageHeight = o.height;
      }

      let scale = Math.min(width / imageWidth, height / imageHeight);
      let aspectRatio = imageWidth / imageHeight;
      let x = width / 2 - (imageWidth / 2) * scale;
      // let y = height / 2 - (imageHeight / 2) * scale;

      let _padding = {
        x: 2 * 50 * aspectRatio,
        y: 2 * 50,
      };

      const ctx = campaignCanvas.getContext("2d");
      ctx.clearRect(0, 0, width, height);

      // for testing,
      // it's helpful to see the entire area of the canvas
      // which is visualized as an orange square
      // ctx.fillStyle = "#ff9900";
      // ctx.fillRect(0, 0, width, height);

      ctx.drawImage(
        campaignImg,
        x + _padding.x,
        height - imageHeight * scale - _padding.y,
        imageWidth * scale - _padding.x * 2,
        imageHeight * scale - _padding.y * 2
      );

      // box
      // ctx.strokeStyle = "#000000";
      // ctx.lineWidth = 10;
      // ctx.strokeRect(10, 10, 4076, 4076);

      campaignTexture.anisotropy = gl.capabilities.getMaxAnisotropy();
      campaignTexture.needsUpdate = true;
    };
    if (campaignLogoUrl) campaignImg.src = campaignLogoUrl;

    return campaignTexture;
  }, [
    campaignLogoUrl,
    campaignCanvas,
    height,
    width,
    campaignImg,
    campaignTexture,
    gl.capabilities,
  ]);

  return (
    <group rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
      {logoUrl && (
        <mesh rotation={[0, 0, Math.PI / 2]} position={[0, 0, 0.01]}>
          <planeBufferGeometry args={[10, 10]} />
          <meshBasicMaterial
            map={logoTextureMap}
            useMap={true}
            transparent={true}
          />
        </mesh>
      )}
      {campaignLogoUrl && (
        <mesh
          rotation={[0, 0, Math.PI / 2]}
          position={[bounds[0] / 2 - 5, -bounds[2] / 2 + 5, 0.01]}
        >
          <planeBufferGeometry args={[10, 10]} />
          <meshBasicMaterial
            map={campaignTextureMap}
            useMap={true}
            transparent={true}
          />
        </mesh>
      )}
      <mesh>
        <planeBufferGeometry args={[bounds[0], bounds[2]]} />
        <meshMatcapMaterial
          color={color}
          map={textureMap}
          matcap={matcap}
          side={THREE.FrontSide}
        />
      </mesh>
    </group>
  );
};

export default BackLayer;
