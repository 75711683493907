import { useMemo, useCallback } from "react";
import * as THREE from "three";

import { useDiscreetTexture } from "../hooks/useDiscreetTexture";
import { useMatcapTexture } from "../hooks/useMatcapTexture";

const PassepartoutLayer = ({
  matCapId,
  color,
  bounds,
  textureId,
  surface = 0,
} = {}) => {
  const textureMap = useDiscreetTexture(textureId, 1, 1);

  const [matcap] = useMatcapTexture(matCapId);

  const CanvasFactory = useCallback(
    (side) => {
      let width = bounds[0],
        length = bounds[2];

      const geometry = new THREE.PlaneBufferGeometry(width, length);
      geometry.rotateX(-Math.PI / 2);
      geometry.translate(0, 0, length / 2);
      geometry.computeBoundingBox();

      return geometry;
    },
    [bounds]
  );

  const frameY = useMemo(() => {
    return -surface * 2;
  }, [surface]);

  return (
    <group position-y={frameY}>
      <mesh
        position-z={-bounds[2] / 2}
        geometry={CanvasFactory()}
        castShadow={true}
        // receiveShadow={true}
      >
        <meshMatcapMaterial
          color={color}
          map={textureMap}
          matcap={matcap}
          side={THREE.BackSide}
        />
      </mesh>
    </group>
  );
};

export default PassepartoutLayer;
