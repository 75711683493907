import qs from "qs";

// const serialize = function (obj) {
//   var str = [];
//   for (var p in obj)
//     if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
//       if (typeof obj[p] === "object") {
//         var d = serialize(obj[p]);
//         console.log("d", d, p);
//         str.push(
//           encodeURIComponent(p) +
//             "=" +
//             encodeURIComponent("{") +
//             d +
//             encodeURIComponent("}")
//         );
//       } else {
//         str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//       }
//     }
//   return str.join("&");
// };

// function deserialize(queryString) {
//   const queryStringPieces = queryString.split("&");
//   const decodedQueryString = {};

//   for (const piece of queryStringPieces) {
//     let [key, value] = piece.split("=");
//     value = value || "";
//     if (value === "null") value = "";
//     if (String(value).indexOf("%23") === 0)
//       value = String(value).replace("%23", "#");
//     if (String(value).indexOf("%5B"))
//     if (!isNaN(value)) value = Number(value);
//     if (has(decodedQueryString, key)) {
//       const currentValueForKey = get(decodedQueryString, key);
//       if (!Array.isArray(currentValueForKey)) {
//         set(decodedQueryString, key, [currentValueForKey, value]);
//       } else {
//         currentValueForKey.push(value);
//       }
//     } else {
//       set(decodedQueryString, key, value);
//     }
//   }
//   return decodedQueryString;
// }

const serialize = (obj) => {
  let r = qs.stringify(obj);
  r = r.replaceAll("=&", "=null&");
  return r;
};

function deserialize(query) {
  const sanitize = (value) => {
    if (value === "null") value = "";
    if (value === "false") value = false;
    if (value === "true") value = true;
    if (!isNaN(value) && value) value = Number(value);

    if (typeof value === "object") {
      for (var i in value) {
        value[i] = sanitize(value[i]);
      }
    }
    return value;
  };
  const r = qs.parse(query);
  for (const v in r) {
    r[v] = sanitize(r[v]);
  }
  return r;
}

export { serialize, deserialize };
