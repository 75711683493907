import React, { useState, useRef, useMemo, useCallback } from "react";
import { Canvas } from "@react-three/fiber";
import { useSearchParams } from "react-router-dom";
import * as THREE from "three";

import OrbitController from "../components/OrbitController";
import Frame from "../components/Frame";
import Effects from "../components/Effects";
import MediaControls from "../components/MediaControls";
import { getDefaultValues } from "../presets/";

const OrbitContext = React.createContext(false);

const Viewer = ({ campaign }) => {
  const [media, setMedia] = useState();
  const [mediaUrl, setMediaUrl] = useState();
  const orbitRef = useRef();
  const [isOrbitting] = useState(false);
  // const [record, setRecord] = useState(false);
  const [searchParams] = useSearchParams();

  const onMediaReady = useCallback((m) => {
    setMedia(m);
  }, []);

  const values = useMemo(() => {
    const d = getDefaultValues(
      window.location.search.slice(1),
      searchParams,
      campaign
    );

    setMediaUrl(d.nft);

    return d;
  }, [searchParams, campaign]);

  // function toggleOrbit() {
  //   setIsOrbitting((isOrbitting) => !isOrbitting);
  // }

  // function resetCamera() {
  //   setIsOrbitting(false);
  //   // orbitRef.current.object.rotation.set(Math.PI / 2, 0, 0);
  //   orbitRef.current.object.position.set(0, 0, 65);
  //   orbitRef.current.reset();
  // }

  // function toggleRecord() {
  //   setRecord((record) => !record);
  // }

  return (
    <>
      <Canvas
        // frameloop="demand"
        pixelRatio={window.devicePixelRatio}
        antialias={false}
        shadows={true}
        camera={{
          position: [0, 0, 75],
          // rotation: [Math.PI / 2, 0, Math.PI],
          near: 1,
          far: 500,
          fov: 45,
        }}
        onCreated={({ gl }) => {
          gl.antialias = true;
          gl.localClippingEnabled = true;
          gl.alpha = true;
          gl.setClearAlpha(0);
          gl.toneMapping = THREE.NoToneMapping;
          // gl.gammaOutput = true;
          gl.outputEncoding = THREE.sRGBEncoding;
          // gl.alpha = true;
          // gl.setClearColor("#f0f0f0");
        }}
      >
        <Effects />

        <OrbitContext.Provider value={orbitRef}>
          <OrbitController
            ref={orbitRef}
            OrbitContext={OrbitContext}
            isOrbitting={isOrbitting}
          />
          <Frame values={values} onMediaReady={onMediaReady} />;
        </OrbitContext.Provider>
      </Canvas>
      <MediaControls mediaUrl={mediaUrl} media={media} />
    </>
  );
};

export default Viewer;
