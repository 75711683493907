const Preset_Arhcan = {
  orientation: "CUSTOM",
  nft_fit: "CONTAIN",
  custom_size: { x: 29.9, y: 50 },
  nft_padding: 0,
  nft_surface: 0.03,
  backsideMatcap: "513A11_CDBEB5_C8811C_9A733F",
  backsideColor: "#ffffff",
  backsideTextureId: "",
  lights: 1,
  style_1: "SIMPLE_BOX",
  dimensions_1: { x: 0.3, y: 1.5 },
  matCapId_1: "513A11_CDBEB5_C8811C_9A733F",
  color_1: "#ffffff",
  textureId_1: "",
  surface_1: -0.11,
  profile_1: "CURVE_5",
  cartouche_1: "cartouche_01.fbx",
  cartoucheOffset_1: 0,
  cartoucheSides_1: 1,
  cartoucheFlip_1: false,
  cartoucheNudge_1: { x: 0, y: 0, z: 0 },
  echoLayout_1: "straight",
  echoMesh_1: "box",
  echoConfig_1: { x: 1, y: 1, z: 1 },
  repeat_1: 1,
  offset_1: 0,
  scale_1: { x: 1, y: 1, z: 1 },
  flowThickness_1: 0.4,
  flowRepeat_1: 1,
  flowRevolutions_1: 0,
  flowColor_1: "#ffffff",
  flowNoiseAmp_1: 0,
  flowOffset_1: 0,
  flowScale_1: 1,
  frameId_1: "frame_01.fbx",
  style_2: "ECHO",
  dimensions_2: { x: 0.9, y: 1.2000000000000008 },
  matCapId_2: "513A11_CDBEB5_C8811C_9A733F",
  color_2: "#ffffff",
  textureId_2: "",
  surface_2: 2,
  profile_2: "BOX",
  cartouche_2: "cartouche_01.fbx",
  cartoucheOffset_2: 0,
  cartoucheSides_2: 1,
  cartoucheFlip_2: false,
  cartoucheNudge_2: { x: 0, y: 0, z: 0 },
  echoLayout_2: "straight",
  echoMesh_2: "sphere",
  echoConfig_2: { x: -11, y: 10.5, z: 3 },
  repeat_2: 2.71,
  offset_2: 0,
  scale_2: {
    x: 1.3800000000000006,
    y: 1.7200000000000006,
    z: 1.4600000000000006,
  },
  flowThickness_2: 0.4,
  flowRepeat_2: 1,
  flowRevolutions_2: 0,
  flowColor_2: "#ffffff",
  flowNoiseAmp_2: 0,
  flowOffset_2: 0,
  flowScale_2: 1,
  frameId_2: "frame_01.fbx",
  style_3: "",
  dimensions_3: { x: 0.8999999999999997, y: 0.5 },
  matCapId_3: "513A11_CDBEB5_C8811C_9A733F",
  color_3: "#ffffff",
  textureId_3: "",
  surface_3: -0.11,
  profile_3: "CURVE",
  cartouche_3: "cartouche_01.fbx",
  cartoucheOffset_3: 0,
  cartoucheSides_3: 1,
  cartoucheFlip_3: false,
  cartoucheNudge_3: { x: 0, y: 0, z: 0 },
  echoLayout_3: "straight",
  echoMesh_3: "box",
  echoConfig_3: { x: 1, y: 1, z: 1 },
  repeat_3: 1,
  offset_3: 0,
  scale_3: { x: 1, y: 1, z: 1 },
  flowThickness_3: 0.4,
  flowRepeat_3: 1,
  flowRevolutions_3: 0,
  flowColor_3: "#ffffff",
  flowNoiseAmp_3: 0,
  flowOffset_3: 0,
  flowScale_3: 1,
  frameId_3: "frame_01.fbx",
  style_4: "SIMPLE_BOX",
  dimensions_4: { x: 1.7000000000000002, y: 0.5 },
  matCapId_4: "513A11_CDBEB5_C8811C_9A733F",
  color_4: "#ffffff",
  textureId_4: "",
  surface_4: -0.01,
  profile_4: "CURVE",
  cartouche_4: "cartouche_04.fbx",
  cartoucheOffset_4: 10,
  cartoucheSides_4: 1,
  cartoucheFlip_4: false,
  cartoucheNudge_4: { x: 0, y: 0, z: 0 },
  echoLayout_4: "straight",
  echoMesh_4: "box",
  echoConfig_4: { x: 1, y: 1, z: 1 },
  repeat_4: 1,
  offset_4: 0,
  scale_4: { x: 1, y: 1, z: 1 },
  flowThickness_4: 0.4,
  flowRepeat_4: 1,
  flowRevolutions_4: 0,
  flowColor_4: "#ffffff",
  flowNoiseAmp_4: 0,
  flowOffset_4: 0,
  flowScale_4: 1,
  frameId_4: "frame_02.fbx",
  style_5: "CARTOUCHE",
  dimensions_5: { x: 0.4, y: 8.700000000000001 },
  matCapId_5: "513A11_CDBEB5_C8811C_9A733F",
  color_5: "#ffffff",
  textureId_5: "",
  surface_5: 0.08999999999999986,
  profile_5: "BOX",
  cartouche_5: "cartouche_02.fbx",
  cartoucheOffset_5: 2.1000000000000005,
  cartoucheSides_5: 5,
  cartoucheFlip_5: false,
  cartoucheNudge_5: { x: 0, y: 0, z: 0 },
  echoLayout_5: "straight",
  echoMesh_5: "box",
  echoConfig_5: { x: 1, y: 1, z: 1 },
  repeat_5: 1,
  offset_5: 0,
  scale_5: { x: 1, y: 1, z: 1 },
  flowThickness_5: 0.4,
  flowRepeat_5: 1,
  flowRevolutions_5: 0,
  flowColor_5: "#ffffff",
  flowNoiseAmp_5: 0,
  flowOffset_5: 0,
  flowScale_5: 1,
  frameId_5: "frame_01.fbx",
  grid: false,
  shadow: false,
  background: false,
  backgroundColor: "#ffffff",
  orbitStyle: "ORBIT_FRONT",
  orbitSpeed: "NORMAL",
  backLogo: "/img/frahm_logo.svg",
};

export default Preset_Arhcan;
