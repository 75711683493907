import Hector from "./Hector";
import Pandora from "./Pandora";
import Agamemnon from "./Agamemnon";
import Medusa from "./Medusa";
import Hades from "./Hades";

// NANSEN
import Aurelius from "./Aurelius";
import Rusticus from "./Rusticus";
import Drusa from "./Drusa";
import Aelia from "./Aelia";
import Cornelius from "./Cornelius";
import Ovidia from "./Ovidia";

// ARCHAN
import Prana from "./Prana";
import Archan from "./Archan";

// TIME
import Time1 from "./Time1";
import Time2 from "./Time2";
import Time3 from "./Time3";
import Time4 from "./Time4";
import Ukraine1 from "./Ukraine1";
import Ukraine2 from "./Ukraine2";
import Ukraine3 from "./Ukraine3";

// GEN ART
import GenArt1 from "./genart1";
import GenArt2 from "./genart2";
import GenArt3 from "./genart3";
import GenArt4 from "./genart4";

// CLONEX
import CloneX1 from "./clonex1";
import CloneX2 from "./clonex2";
import CloneX3 from "./clonex3";
import CloneX4 from "./clonex4";
import CloneX5 from "./clonex5";
import CloneX6 from "./clonex6";
import CloneX7 from "./clonex7";
import CloneX8 from "./clonex8";
import CloneX9 from "./clonex9";

import getDefaultValues from "./getDefaultValues";

const PresetDictionary = {
  0: { name: "Prana", value: Prana },
  1: { name: "Hector", value: Hector },
  2: { name: "Pandora", value: Pandora },
  3: { name: "Agamemnon", value: Agamemnon },
  4: { name: "Medusa", value: Medusa },
  5: { name: "Hades", value: Hades },
  6: { name: "Aurelius", value: Aurelius },
  7: { name: "Rusticus", value: Rusticus },
  8: { name: "Drusa", value: Drusa },
  9: { name: "Aelia", value: Aelia },
  10: { name: "Cornelius", value: Cornelius },
  11: { name: "Ovidia", value: Ovidia },
  12: { name: "Archan", value: Archan },
  time1: { name: "Time 1", value: Time1 },
  time2: { name: "Time 2", value: Time2 },
  time3: { name: "Time 3", value: Time3 },
  time4: { name: "Time 4", value: Time4 },
  ukraine1: { name: "Ukraine 1", value: Ukraine1 },
  ukraine2: { name: "Ukraine 2", value: Ukraine2 },
  ukraine3: { name: "Ukraine 3", value: Ukraine3 },
  genart1: { name: "Gen.Art 1", value: GenArt1 },
  genart2: { name: "Gen.Art 2", value: GenArt2 },
  genart3: { name: "Gen.Art 3", value: GenArt3 },
  genart4: { name: "Gen.Art 4", value: GenArt4 },
  clonex1: { name: "CloneX 1", value: CloneX1 }, // black
  clonex2: { name: "CloneX 2", value: CloneX2 },
  clonex3: { name: "CloneX 3", value: CloneX3 },
  clonex4: { name: "CloneX 4", value: CloneX4 }, // salmon
  clonex5: { name: "CloneX 5", value: CloneX5 },
  clonex6: { name: "CloneX 6", value: CloneX6 },
  clonex7: { name: "CloneX 7", value: CloneX7 },
  clonex8: { name: "CloneX 8", value: CloneX8 },
  clonex9: { name: "CloneX 9", value: CloneX9 },
};

export {
  Hector,
  Pandora,
  Agamemnon,
  Medusa,
  Hades,
  Aurelius,
  Rusticus,
  Drusa,
  Aelia,
  Cornelius,
  Ovidia,
  Archan,
  getDefaultValues,
  PresetDictionary,
};
