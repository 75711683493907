import { useMemo } from "react";
import * as THREE from "three";
import useIsMobile from "./useIsMobile";

export function useDiscreetTexture(textureId = "", repeatX = 1, repeatY = 1) {
  const isMobile = useIsMobile();
  const texture = useMemo(() => {
    const canvas = document.createElement("canvas");
    canvas.width = isMobile ? 512 : 1024;
    canvas.height = isMobile ? 512 : 1024;

    const texture = new THREE.CanvasTexture(canvas);
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.encoding = THREE.sRGBEncoding;
    texture.repeat.set(repeatX, repeatY);

    const i = document.createElement("img");
    i.setAttribute("crossOrigin", "anonymous");

    i.onload = (e) => {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, 1024, 1024);
      ctx.drawImage(i, 0, 0, 1024, 1024);

      texture.needsUpdate = true;
    };
    i.src = textureId ? textureId : "/img/blank.jpg";
    return texture;
  }, [textureId, repeatX, repeatY, isMobile]);

  return texture;
}
