const Cornelius = {
  orientation: "SQUARE",
  nft_fit: "CONTAIN",
  nft_padding: 0,
  nft_surface: 0.03,
  backsideMatcap: "",
  backsideColor: "#ffffff",
  backsideTextureId: "",
  lights: 0,
  style_1: "SIMPLE_BOX",
  dimensions_1: { x: 2.9, y: 1.5000000000000002 },
  matCapId_1: "3B3C3F_DAD9D5_929290_ABACA8",
  color_1: "#ffffff",
  textureId_1: "/img/diffuse/187.jpg",
  surface_1: -0.11,
  profile_1: "CURVE_5",
  cartouche_1: "cartouche_01.fbx",
  cartoucheOffset_1: 0,
  cartoucheSides_1: 1,
  echoLayout_1: "straight",
  echoMesh_1: "box",
  echoConfig_1: { x: 1, y: 1, z: 1 },
  repeat_1: 1,
  offset_1: 0,
  scale_1: { x: 1, y: 1, z: 1 },
  frameId_1: "frame_01.fbx",
  style_2: "ECHO",
  dimensions_2: { x: 0.8999999999999997, y: 0.6000000000000001 },
  matCapId_2: "3B3C3F_DAD9D5_929290_ABACA8",
  color_2: "#ffffff",
  textureId_2: "/img/diffuse/187.jpg",
  surface_2: 0,
  profile_2: "BOX",
  cartouche_2: "cartouche_01.fbx",
  cartoucheOffset_2: 0,
  cartoucheSides_2: 1,
  echoLayout_2: "straight",
  echoMesh_2: "sphere",
  echoConfig_2: { x: -11, y: 10.5, z: 3 },
  repeat_2: 1.18,
  offset_2: 0,
  scale_2: {
    x: 1.3800000000000006,
    y: 1.7200000000000006,
    z: 1.4600000000000006,
  },
  frameId_2: "frame_01.fbx",
  style_3: "SIMPLE_BOX",
  dimensions_3: { x: 0.8999999999999997, y: 0.5 },
  matCapId_3: "3B3C3F_DAD9D5_929290_ABACA8",
  color_3: "#ffffff",
  textureId_3: "/img/diffuse/187.jpg",
  surface_3: -0.11,
  profile_3: "CURVE",
  cartouche_3: "cartouche_01.fbx",
  cartoucheOffset_3: 0,
  cartoucheSides_3: 1,
  echoLayout_3: "straight",
  echoMesh_3: "box",
  echoConfig_3: { x: 1, y: 1, z: 1 },
  repeat_3: 1,
  offset_3: 0,
  scale_3: { x: 1, y: 1, z: 1 },
  frameId_3: "frame_01.fbx",
  style_4: "SIMPLE_BOX",
  dimensions_4: { x: 0.5, y: 0.5 },
  matCapId_4: "3B3C3F_DAD9D5_929290_ABACA8",
  color_4: "#ffffff",
  textureId_4: "/img/diffuse/187.jpg",
  surface_4: -0.01,
  profile_4: "CURVE",
  cartouche_4: "cartouche_04.fbx",
  cartoucheOffset_4: 10,
  cartoucheSides_4: 1,
  echoLayout_4: "straight",
  echoMesh_4: "box",
  echoConfig_4: { x: 1, y: 1, z: 1 },
  repeat_4: 1,
  offset_4: 0,
  scale_4: { x: 1, y: 1, z: 1 },
  frameId_4: "frame_02.fbx",
  style_5: "CARTOUCHE",
  dimensions_5: { x: 0.5, y: 6.500000000000001 },
  matCapId_5: "3B3C3F_DAD9D5_929290_ABACA8",
  color_5: "#ffffff",
  textureId_5: "/img/diffuse/187.jpg",
  surface_5: 1.17,
  profile_5: "BOX",
  cartouche_5: "cartouche_03.fbx",
  cartoucheOffset_5: 3.4000000000000004,
  cartoucheSides_5: 5,
  echoLayout_5: "straight",
  echoMesh_5: "box",
  echoConfig_5: { x: 1, y: 1, z: 1 },
  repeat_5: 1,
  offset_5: 0,
  scale_5: { x: 1, y: 1, z: 1 },
  frameId_5: "frame_01.fbx",
  grid: false,
  shadow: false,
  background: false,
  backgroundColor: "#ffffff",
  orbitStyle: "ORBIT_FRONT",
  orbitSpeed: "SLOW",
};

export default Cornelius;
