import { useEffect, useMemo, useState } from "react";
import * as THREE from "three";
import { useCustomCompareEffect } from "use-custom-compare";
import useIsMobile from "../hooks/useIsMobile";

const NFTLayer = ({
  padding,
  mapUri = "/img/blank.jpg",
  surface = 0,
  bounds,
  imageSize,
  fit,
  onMediaReady = () => console.log("Media ready"),
} = {}) => {
  const [isReady, setIsReady] = useState(false);
  const [videoNode, setVideoNode] = useState();
  const [sourceNode, setSourceNode] = useState();
  const [imageNode, setImageNode] = useState();
  const isMobile = useIsMobile();
  const isVideo = useMemo(() => {
    return mapUri.indexOf(".mp4") > -1;
  }, [mapUri]);

  const [mediaDimensions, setMediaDimensions] = useState([0, 0]);

  useEffect(() => {
    setIsReady(false);
  }, [mapUri]);

  useCustomCompareEffect(
    () => {
      let vn, sn, i;
      if (isVideo) {
        setIsReady(false);
        vn = document.createElement("video");
        vn.setAttribute("crossOrigin", "anonymous");
        vn.playsinline = true;
        vn.autoplay = true;
        vn.loop = true;
        vn.controls = true;
        vn.muted = true;

        sn = document.createElement("source");

        sn.type = "video/mp4";
        vn.appendChild(sn);
        if (!isMobile) vn.play();

        vn.addEventListener("loadedmetadata", function (e) {
          setMediaDimensions([vn.videoWidth, vn.videoHeight]);
          setIsReady(true);
        });

        // vn.style.position = "fixed";
        // vn.style.top = 0;
        // vn.style.left = 0;
        // vn.style.width = "200px";
        // vn.style.height = "200px";
        // document.body.appendChild(vn);

        setVideoNode(vn);
        setSourceNode(sn);
        onMediaReady(vn);

        if (mapUri) sn.src = mapUri;
      } else if (!isVideo) {
        i = document.createElement("img");
        i.setAttribute("crossOrigin", "anonymous");

        i.onload = (e) => {
          let mediaWidth = i.naturalWidth;
          let mediaHeight = i.naturalHeight;
          if (mediaWidth <= 1 && e.path && e.path[0]) {
            mediaWidth = e.path[0].naturalWidth;
            mediaHeight = e.path[0].naturalHeight;
          }
          setMediaDimensions([mediaWidth, mediaHeight]);
          setIsReady(true);
        };

        setImageNode(i);
        if (mapUri) i.src = mapUri;
      }
    },
    [isVideo, mapUri, onMediaReady],
    (prevDeps, nextDeps) => {
      const e = prevDeps[1] === nextDeps[1];
      return e;
    }
  );

  useEffect(() => {
    if (sourceNode && mapUri) {
      sourceNode.src = mapUri;
    }
  }, [sourceNode, mapUri]);

  const useMap = useMemo(() => {
    const u = !!(mapUri !== "/img/blank.jpg");
    return u;
  }, [mapUri]);

  const textureMap = useMemo(() => {
    if (!isReady) return null;
    if (isVideo) {
      const videoTexture = new THREE.VideoTexture(videoNode);
      videoTexture.wrapS = videoTexture.wrapT = THREE.ClampToEdgeWrapping;
      videoTexture.repeat.set(1, 1);
      videoTexture.needsUpdate = true;
      return videoTexture;
    } else {
      const canvas = document.createElement("canvas");
      canvas.width = isMobile ? 1024 : 4096;
      canvas.height = isMobile ? 1024 : 4096;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(imageNode, 0, 0, canvas.width, canvas.height);

      const texture = new THREE.CanvasTexture(canvas);
      texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
      texture.repeat.set(1, 1);
      texture.encoding = THREE.sRGBEncoding;
      texture.needsUpdate = true;
      return texture;
    }
  }, [isVideo, videoNode, isReady, imageNode, isMobile]);

  //   let scale = Math.min(width / imageWidth, height / imageHeight);
  //   let aspectRatio = imageWidth / imageHeight;
  //   let clipH = 0,
  //     clipV = 0;

  //   if (aspectRatio === 1) {
  //     if (orientation === "SQUARE") {
  //       clipH = 0;
  //       clipV = 0;
  //     } else if (orientation === "PORTRAIT") {
  //       if (fit === "COVER") {
  //         clipH = (width - (width * 5) / 7) / 2;
  //         clipV = 0;
  //       } else if (fit === "CONTAIN") {
  //         scale = ((width / imageWidth) * 5) / 7;
  //       }
  //     } else if (orientation === "LANDSCAPE") {
  //       if (fit === "COVER") {
  //         clipH = 0;
  //         clipV = (height - (height * 5) / 7) / 2;
  //       } else if (fit === "CONTAIN") {
  //         scale = ((width / imageWidth) * 5) / 7;
  //         clipH = 0;
  //         clipV = (height - (height * 5) / 7) / 2;
  //       }
  //     }
  //     // nft is landscape
  //   } else if (aspectRatio > 1) {
  //     clipH = 0;
  //     clipV = 0;
  //     if (orientation === "SQUARE") {
  //       if (fit === "COVER") {
  //         scale = height / imageHeight;
  //       } else if (fit === "CONTAIN") {
  //       }
  //     } else if (orientation === "PORTRAIT") {
  //       if (fit === "COVER") {
  //         scale = height / imageHeight;
  //         clipH = (width - (width * 5) / 7) / 2;
  //       } else if (fit === "CONTAIN") {
  //         scale = ((width / imageWidth) * 5) / 7;
  //       }
  //     } else if (orientation === "LANDSCAPE") {
  //       if (fit === "COVER") {
  //         scale = height / imageHeight;
  //         clipV = (height - (height * 5) / 7) / 2;
  //       } else if (fit === "CONTAIN") {
  //         // scale = width / imageWidth;
  //       }
  //     }
  //     // nft is a portrait
  //   } else if (aspectRatio < 1) {
  //     clipH = 0;
  //     clipV = 0;
  //     if (orientation === "SQUARE") {
  //       if (fit === "COVER") {
  //         scale = width / imageWidth;
  //       } else if (fit === "CONTAIN") {
  //         // scale = height / imageHeight;
  //       }
  //     } else if (orientation === "PORTRAIT") {
  //       if (fit === "COVER") {
  //         scale = (width / imageWidth) * aspectRatio;
  //         clipH = (width - (width * 5) / 7) / 2;
  //       } else if (fit === "CONTAIN") {
  //         scale = ((width / imageWidth) * 5) / 7;
  //       }
  //     } else if (orientation === "LANDSCAPE") {
  //       if (fit === "COVER") {
  //         scale = width / imageWidth;
  //         clipV = (height - (height * 5) / 7) / 2;
  //       } else if (fit === "CONTAIN") {
  //         scale = ((height / imageHeight) * 5) / 7;
  //       }
  //     }
  //   }

  //   let x = width / 2 - (imageWidth / 2) * scale;
  //   let y = height / 2 - (imageHeight / 2) * scale;

  //   let _padding = {
  //     x: padding * 15 * aspectRatio,
  //     y: padding * 15,
  //   };

  //   if (window.innerWidth < 1200) {
  //     // original measurements are based on 4096 sized canvas
  //     // but mobile canvas is 1024,
  //     // or 1/4 the size.
  //     _padding.x /= 4;
  //     _padding.y /= 4;
  //   }

  //   const ctx = canvas.getContext("2d");
  //   ctx.clearRect(0, 0, width, height);

  //   // clears clipping rect
  //   canvas.width = 10;
  //   canvas.width = width;
  //   ctx.save();
  //   ctx.fillStyle = "#FF0000";
  //   ctx.beginPath();
  //   ctx.rect(clipH, clipV, width - clipH * 2, height - clipV * 2);
  //   ctx.clip();
  //   ctx.closePath();

  //   // for testing,
  //   // it's helpful to see the entire area of the canvas
  //   // which is visualized as an pink square
  //   // it may be required to disable the `clip()` function previously summoned
  //   // ctx.fillStyle = "#ff0099";
  //   // ctx.fillRect(0, 0, width, height);

  //   ctx.drawImage(
  //     i,
  //     x + _padding.x,
  //     y + _padding.y,
  //     imageWidth * scale - _padding.x * 2,
  //     imageHeight * scale - _padding.y * 2
  //   );

  //   // for testing,
  //   // ctx.lineStyle = "#ff9900";
  //   // ctx.lineWidth = 50;
  //   // ctx.beginPath();
  //   // ctx.rect(0, 0, width, height);
  //   // ctx.stroke();

  //   ctx.restore();

  //   texture.needsUpdate = true;
  // }, [mediaDimensions, ])

  const size = useMemo(() => {
    if (!isReady) return;
    let d = [bounds[2], bounds[0]];

    const imageAspect = mediaDimensions[0] / mediaDimensions[1];
    const screenAspect = bounds[2] / bounds[0];
    const scale = imageAspect / screenAspect;
    const offsetX = (imageAspect - screenAspect) / imageAspect;
    const offsetY = (screenAspect - imageAspect) / screenAspect;
    const alignH = 0.5;
    const alignV = 0.5;

    let _padding = {
      // x: padding * 15 * imageAspect,
      // y: padding * 15,
      x: 0.2 * padding * imageAspect,
      y: 0.2 * padding,
    };

    if (window.innerWidth < 1200) {
      // original measurements are based on 4096 sized canvas
      // but mobile canvas is 1024,
      // or 1/4 the size.
      _padding.x /= 4;
      _padding.y /= 4;
    }
    switch (fit) {
      case "CONTAIN":
        textureMap.offset.set(0, 0);
        textureMap.repeat.set(1, 1);

        if (screenAspect < imageAspect) {
          d[0] = bounds[2] - _padding.x * 2;
          d[1] = bounds[0] / scale - _padding.y * 2;
        } else {
          d[0] = bounds[2] * scale - _padding.x * 2;
          d[1] = bounds[0] - _padding.y * 2;
        }
        break;
      case "COVER":
        // adjust texturemap repeat and offset instead of making geo larger
        if (screenAspect < imageAspect) {
          textureMap.offset = new THREE.Vector2(offsetX * alignH, 0);
          textureMap.repeat = new THREE.Vector2(1 / scale, 1);
        } else {
          textureMap.offset = new THREE.Vector2(0, offsetY * alignV);
          textureMap.repeat = new THREE.Vector2(1, scale);
        }
        break;
      default:
        break;
    }

    return d;
  }, [bounds, mediaDimensions, fit, isReady, padding, textureMap]);

  if (!isReady) return null;

  return (
    <mesh
      position={[0, -surface, 0]}
      rotation={[Math.PI / 2, 0, Math.PI / 2]}
      castShadow={true}
    >
      <planeGeometry args={size} />
      {/* <meshBasicMaterial color={0xff99ff} /> */}
      <meshBasicMaterial
        map={textureMap}
        useMap={useMap}
        reflectivity={0.05}
        roughness={0.4}
        metalness={0.5}
        color={0xffffff}
        side={THREE.FrontSide}
        opacity={useMap ? 1 : 0}
        transparent
      />
    </mesh>
  );
};

export default NFTLayer;
