import { Suspense, useCallback, useEffect, useState } from "react";

import ModelLayer from "./ModelLayer";
import SimpleBoxLayer from "./SimpleBoxLayer";
import EchoLayer from "./EchoLayer";
import Background from "./Background";
import CartoucheLayer from "./CartoucheLayer";
import LinerLayer from "./LinerLayer";
import FlowLayer from "./FlowLayer";
import PassepartoutLayer from "./PassepartoutLayer";
import NFTLayer from "./NFTLayer";
import BackLayer from "./BackLayer";

const Frame = ({ values, position = [0, 0, 0], onMediaReady } = {}) => {
  const [imageSize, setImageSize] = useState([50, 50]);

  // const [videoUri] = useState("/vid/squiggle.mp4");
  // const videoTexture = useMemo(() => {
  //   const videoNode = document.createElement("video");
  //   videoNode.width = 1990;
  //   videoNode.height = 1516;
  //   videoNode.crossOrigin = "anonymous";
  //   videoNode.playsinline = true;
  //   videoNode.autoplay = true;
  //   videoNode.loop = true;

  //   const sourceNode = document.createElement("source");
  //   sourceNode.src = videoUri;
  //   sourceNode.type = "video/mp4";
  //   videoNode.appendChild(sourceNode);

  //   return new THREE.VideoTexture(videoNode);
  // }, [videoUri]);

  useEffect(() => {
    switch (values.orientation) {
      case "CUSTOM":
        setImageSize([values.custom_size.x, values.custom_size.y]);
        break;
      case "ARCHAN":
      case "ARCHAN-DAY":
      case "ARCHAN-NIGHT":
        setImageSize([28, 50]);
        break;
      case "PORTRAIT":
        setImageSize([35, 50]);
        break;
      case "LANDSCAPE":
        setImageSize([50, 35]);
        // setImageSize([48, 27]);
        break;
      case "SQUARE":
      default:
        setImageSize([50, 50]);
        break;
    }
  }, [setImageSize, values.orientation, values.custom_size]);

  const getBounds = (i, values) => {
    var bounds = [imageSize[1], 0, imageSize[0]];

    // outer layer
    let B = bounds;
    if (i < 2) return B;

    for (var j = 2; j <= i; j++) {
      const style = values[`style_${j - 1}`];
      if (style === "SIMPLE_BOX" || style === "MODEL") {
        bounds[0] -= values[`dimensions_${j - 1}`].x * 2;
        bounds[1] = values[`dimensions_${j - 1}`].y * 2;
        bounds[2] -= values[`dimensions_${j - 1}`].x * 2;

        B = bounds;
      }
    }

    return B;
  };

  const renderLayer = useCallback(
    (i, values) => {
      var bounds = [imageSize[1], 0, imageSize[0]];
      var prevDimensions = [1, 1, 1];
      for (var j = 2; j <= i; j++) {
        const style = values[`style_${j - 1}`];
        if (style === "SIMPLE_BOX" || style === "MODEL") {
          bounds[0] -= values[`dimensions_${j - 1}`].x * 2;
          bounds[1] = values[`dimensions_${j - 1}`].y * 2;
          bounds[2] -= values[`dimensions_${j - 1}`].x * 2;

          prevDimensions = [
            values[`dimensions_${j - 1}`].x,
            values[`dimensions_${j - 1}`].y,
            values[`dimensions_${j - 1}`].x,
          ];
        }
      }
      var prevBounds = [...bounds];

      switch (values[`style_${i}`]) {
        case "MODEL":
          return (
            <ModelLayer
              index={i}
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              frameId={values[`frameId_${i}`]}
              surface={values[`surface_${i}`]}
              // dimensions={values[`dimensions_${i}`]}
              // bounds={bounds}
            />
          );

        case "SIMPLE_BOX":
          return (
            <SimpleBoxLayer
              index={i}
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              dimensions={values[`dimensions_${i}`]}
              bounds={bounds}
              imageSize={imageSize}
              profile={values[`profile_${i}`]}
              surface={values[`surface_${i}`]}
            />
          );

        case "PASSEPARTOUT":
          return (
            <PassepartoutLayer
              index={i}
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              dimensions={values[`dimensions_${i}`]}
              bounds={bounds}
              imageSize={imageSize}
              surface={values[`surface_${i}`]}
            />
          );

        case "ECHO":
          return (
            <EchoLayer
              index={i}
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              dimensions={values[`dimensions_${i}`]}
              bounds={bounds}
              imageSize={imageSize}
              repeat={values[`repeat_${i}`]}
              offset={values[`offset_${i}`]}
              surface={values[`surface_${i}`]}
              scale={values[`scale_${i}`]}
              echoLayout={values[`echoLayout_${i}`]}
              echoMesh={values[`echoMesh_${i}`]}
              echoConfig={values[`echoConfig_${i}`]}
              prevBounds={prevBounds}
              prevDimensions={prevDimensions}
            />
          );
        case "CARTOUCHE":
          return (
            <CartoucheLayer
              index={i}
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              cartoucheId={values[`cartouche_${i}`]}
              cartoucheOffset={values[`cartoucheOffset_${i}`]}
              cartoucheSides={values[`cartoucheSides_${i}`]}
              bounds={bounds}
              surface={values[`surface_${i}`]}
              imageSize={imageSize}
              dimensions={values[`dimensions_${i}`]}
              prevBounds={prevBounds}
              prevDimensions={prevDimensions}
              flip={values[`cartoucheFlip_${i}`]}
              nudge={values[`cartoucheNudge_${i}`]}
            />
          );
        case "LINER":
          return (
            <LinerLayer
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              bounds={bounds}
              imageSize={imageSize}
              dimensions={values[`dimensions_${i}`]}
              offset={values[`offset_${i}`]}
              surface={values[`surface_${i}`]}
              prevBounds={prevBounds}
              prevDimensions={prevDimensions}
            />
          );
        case "FLOW":
          return (
            <FlowLayer
              textureId={values[`textureId_${i}`]}
              matCapId={values[`matCapId_${i}`]}
              color={values[`color_${i}`]}
              bounds={bounds}
              imageSize={imageSize}
              surface={values[`surface_${i}`]}
              dimensions={values[`dimensions_${i}`]}
              prevBounds={prevBounds}
              prevDimensions={prevDimensions}
              repeat={values[`flowRepeat_${i}`]}
              thickness={values[`flowThickness_${i}`]}
              revolutions={values[`flowRevolutions_${i}`]}
              color2={values[`flowColor_${i}`]}
              offset={values[`flowOffset_${i}`]}
              noiseAmp={values[`flowNoiseAmp_${i}`]}
              flowScale={values[`flowScale_${i}`]}
            />
          );
        // case "NFT":
        //   return (
        //     <NFTLayer
        //       mapUri={values.nft}
        //       orientation={values.orientation}
        //       fit={values.nft_fit}
        //       padding={values.nft_padding}
        //       surface={values.nft_surface}
        //       bounds={bounds}
        //       imageSize={imageSize}
        //     />
        //   );
        case "BACK":
          var drawBackground = values.nft;
          for (var li = 1; li <= 5; li++) {
            if (values[`style_${li}`] === "SIMPLE_BOX") {
              drawBackground = true;
            }
          }
          if (!drawBackground) return null;
          return (
            <BackLayer
              bounds={bounds}
              matCapId={values.backsideMatcap}
              color={values.backsideColor}
              textureId={values.backsideTextureId}
              logoUrl={values.backLogo}
              campaignLogoUrl={values.campaignLogo}
            />
          );

        default:
          break;
      }
      return null;
    },
    [imageSize]
  );

  return (
    <group position={position}>
      <Suspense fallback={null}>
        <group rotation={[-Math.PI / 2, Math.PI / 2, 0]}>
          {renderLayer(1, values)}
          {renderLayer(2, values)}
          {renderLayer(3, values)}
          {renderLayer(4, values)}
          {renderLayer(5, values)}
          {renderLayer(6, values)}
          {/* {renderLayer(6, { ...values, style_6: "NFT" })} */}
          <NFTLayer
            mapUri={values.nft}
            orientation={values.orientation}
            fit={values.nft_fit}
            padding={values.nft_padding}
            surface={values.nft_surface}
            bounds={getBounds(7, values)}
            imageSize={imageSize}
            onMediaReady={onMediaReady}
          />
          {renderLayer(8, { ...values, style_8: "BACK" })}

          {/* MARKERS */}
          {values.grid && <gridHelper args={[50, 10]} position-y={-0.001} />}
        </group>
        <Background
          shadow={values.shadow}
          lights={values.lights}
          backgroundVisible={values.background}
          backgroundColor={values.backgroundColor}
        />
      </Suspense>
    </group>
  );
};

export default Frame;
