import * as THREE from "three";
import { useFBX } from "@react-three/drei";

import { useMatcapTexture } from "../hooks/useMatcapTexture";
import { useDiscreetTexture } from "../hooks/useDiscreetTexture";

const ModelLayer = ({
  textureId,
  matCapId,
  color,
  frameId,
  surface = 0,
} = {}) => {
  const textureMap = useDiscreetTexture(textureId, 4, 4);

  const [matcap] = useMatcapTexture(matCapId);

  const fbx = useFBX(`/models/${frameId}`);

  return (
    <group
      // scale={[0.075, 0.1, 0.11]} // fidenza
      // scale={[0.1, 0.1, 0.1]} // bit wide
      scale={[0.08, 0.1, 0.098]} // square
      rotation-y={Math.PI / 2}
      position-y={surface} // some frames need y position adjusted
    >
      {fbx.children.map((c, i) => {
        return (
          <mesh {...c} key={`mesh_${frameId}_${i}`} castShadow={true}>
            <meshMatcapMaterial
              color={color}
              map={textureMap}
              matcap={matcap}
              side={THREE.DoubleSide}
            />
          </mesh>
        );
      })}
    </group>
  );
};

export default ModelLayer;
