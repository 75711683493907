import { useRef, useMemo } from "react";
import * as THREE from "three";
// import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";

import { useMatcapTexture } from "../hooks/useMatcapTexture";
import useClippingPlanes from "../hooks/useClippingPlanes";
import { useDiscreetTexture } from "../hooks/useDiscreetTexture";

const LinerEdge = ({
  side,
  matCapId,
  color,
  dimensions,
  bounds,
  repeat,
  offset,
  // scale,
  clippingPlanes,
  textureId,
  prevDimensions,
  imageSize,
  ...props
} = {}) => {
  const meshRef = useRef();
  const [matcap] = useMatcapTexture(matCapId);
  const textureMap = useDiscreetTexture(textureId, 1, 1);

  const geometry = useMemo(() => {
    // const s = new THREE.Vector3(
    //   -bounds[0] / 2 - offset,
    //   0,
    //   -bounds[2] / 2 - offset
    // );
    // const e = new THREE.Vector3(
    //   -bounds[0] / 2 - offset,
    //   0,
    //   bounds[2] / 2 + offset
    // );

    // let svg = new THREE.Shape();
    // const profile = "LINER_1";
    // const loader = new SVGLoader();
    // const svgData = loader.parse(Liners[profile]);
    // svgData.paths.forEach((path, i) => {
    //   svg = path.toShapes(true)[0];
    // });

    // const points = [];
    // for (var i = 0, c, l = svg.curves.length; i < l; i++) {
    //   c = svg.curves[i];
    //   if (c.type === "CubicBezierCurve") {
    //     points.push(new THREE.Vector3(c.v3.x, c.v3.y, 0));
    //   }
    // }

    const points = [];
    let w, h;
    if (side === "T" || side === "B") {
      w = imageSize[0];
      h = imageSize[1];
    } else {
      w = imageSize[1];
      h = imageSize[0];
    }
    points.push(new THREE.Vector3(-w / 2, 0, 3));
    points.push(new THREE.Vector3(-w / 4, -1, 2));
    points.push(new THREE.Vector3(-0.1, 0, 1));
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(new THREE.Vector3(0.1, 0, 1));
    points.push(new THREE.Vector3(w / 4, -1, 2));
    points.push(new THREE.Vector3(w / 2, 0, 3));

    const extrudePath = new THREE.CatmullRomCurve3(points);

    // const shape = new THREE.Shape();
    // shape.moveTo(s.x, s.z);
    // var r, i, x, y;
    // for (i = 0; i < 10; i++) {
    //   r = i / 10;
    //   x = s.x;
    //   y = s.z + r * (e.z - s.z);
    //   shape.quadraticCurveTo(x, 0, x + (i % 2), y);
    // }
    // shape.lineTo(e.x, e.z);
    // for (i = 10; i > 0; i--) {
    //   r = i / 10;
    //   x = s.x;
    //   y = s.z + r * (e.z - s.z);
    //   shape.quadraticCurveTo(x, 0, 0.1 + x + (i % 2), y);
    // }

    const extrudeSettings = {
      steps: 500,
      depth: 2,
      bevelEnabled: true,
      bevelThickness: 2,
      bevelSize: 1,
      bevelOffset: 0,
      bevelSegments: 4,
      extrudePath: extrudePath,
    };

    // triangle that tapers in towards the viewer
    const stamp = new THREE.Shape();
    stamp.moveTo(1, 0.5);
    stamp.lineTo(0, 0);
    stamp.lineTo(0, 1);

    const geometry = new THREE.ExtrudeGeometry(stamp, extrudeSettings);
    geometry.center();

    geometry.rotateX(-Math.PI / 2);
    geometry.rotateY(-Math.PI / 2);
    geometry.translate(-h / 2 + 1, -2, 0);
    // geometry.computeBoundingBox();
    // const b = geometry.boundingBox;
    return geometry;

    // const dotCount = 50;
    // let m = mergeBufferGeometries([geometry]);
    // let dots = new THREE.BoxBufferGeometry(0.5, 0.5, 0.5);
    // dots = dots.toNonIndexed();
    // const extrudedPoints = extrudePath.getSpacedPoints(dotCount);
    // for (var i = 0, r, p, dots2; i < dotCount; i++) {
    //   r = i / dotCount;
    //   // t = extrudePath.getTangentAt(r);
    //   p = extrudedPoints[i];
    //   dots2 = dots.clone();
    //   let _s = (i - dotCount) / dotCount + 0.01;
    //   dots2.scale(_s, _s, _s);
    //   // dots2.scale(1, 1, (i % 2) + 1);
    //   dots.rotateZ((r * Math.PI) / 16);
    //   // dots.rotateX(-t.y);
    //   dots2.translate(-h / 2 + 1, -2, 0);
    //   dots2.translate(p.y, p.z - 4, p.x); // first arg as 0 to keep in straight lines

    //   m = mergeBufferGeometries([m, dots2]);
    // }

    // return m;

    // WARNING: SLOW
    // const boxGeometry = new THREE.BoxBufferGeometry(bounds[2], 5, bounds[2]);
    // boxGeometry.translate(0, 3, 0);
    // const boxMesh = new THREE.Mesh(boxGeometry, new THREE.MeshBasicMaterial());

    // const mesh = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial());

    // const subRes = CSG.subtract(boxMesh, mesh);
    // console.log("subRes", subRes);
    // return subRes.geometry;
  }, [side, imageSize]);

  return (
    <mesh
      ref={meshRef}
      receiveShadow={true}
      castShadow={true}
      geometry={geometry}
      {...props}
    >
      <meshMatcapMaterial
        matcap={matcap}
        color={color}
        map={textureMap}
        clippingPlanes={clippingPlanes}
      />
    </mesh>
  );
};

const LinerLayer = ({ index, dimensions, bounds, ...props } = {}) => {
  const clippingPlanes = useClippingPlanes(1, 1);

  return (
    <group>
      <LinerEdge
        side="T"
        dimensions={dimensions}
        bounds={bounds}
        clippingPlanes={clippingPlanes.top}
        {...props}
      />
      <LinerEdge
        side="B"
        rotation-y={Math.PI}
        dimensions={dimensions}
        bounds={bounds}
        clippingPlanes={clippingPlanes.bottom}
        {...props}
      />
      <LinerEdge
        side="L"
        rotation-y={(Math.PI / 2) * 3}
        dimensions={dimensions}
        bounds={bounds}
        clippingPlanes={clippingPlanes.left}
        {...props}
      />
      <LinerEdge
        side="R"
        rotation-y={Math.PI / 2}
        dimensions={dimensions}
        bounds={bounds}
        clippingPlanes={clippingPlanes.right}
        {...props}
      />
    </group>
  );
};

export default LinerLayer;
