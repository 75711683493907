import { useMemo, useCallback } from "react";
import * as THREE from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";

import { useMatcapTexture } from "../hooks/useMatcapTexture";
import { useDiscreetTexture } from "../hooks/useDiscreetTexture";
import useClippingPlanes from "../hooks/useClippingPlanes";
import * as Profiles from "./Profiles";

const SimpleBoxLayer = ({
  matCapId,
  color,
  dimensions,
  bounds,
  imageSize,
  profile = "BOX",
  textureId,
  surface = 0,
} = {}) => {
  const textureMap = useDiscreetTexture(textureId, 0.05, 1);
  const textureMap2 = useDiscreetTexture(textureId, 1, 0.05);

  const [matcap] = useMatcapTexture(matCapId);

  const boxArgs = useMemo(() => {
    return {
      h: [bounds[0], dimensions.y, dimensions.x],
      v: [dimensions.x, dimensions.y, bounds[2]],
    };
  }, [dimensions, bounds]);

  const clippingPlanes = useClippingPlanes(
    1,
    1,
    imageSize[0],
    imageSize[1],
    0.001
  );

  const CurveFactory = useCallback(
    (side, profile) => {
      let width = 1,
        // length = 1,
        depth = 1;
      if (side === "L" || side === "R") {
        width = boxArgs.h[2];
        // length = boxArgs.v[0];
        depth = boxArgs.h[0];
      } else if (side === "T" || side === "B") {
        width = boxArgs.h[2];
        // length = boxArgs.v[0];
        depth = boxArgs.v[2];
      }

      let shape = new THREE.Shape();
      switch (true) {
        case profile === "CURVE":
          shape.moveTo(0, 0);
          shape.lineTo(0, 0);
          shape.lineTo(0.15, 0);
          shape.lineTo(0.5, 0.25);
          shape.lineTo(0.5, 0.5);
          shape.lineTo(1, 1);
          shape.lineTo(0, 1);
          break;
        case profile.indexOf("CURVE_") > -1:
          const loader = new SVGLoader();
          const svgData = loader.parse(Profiles[profile]);
          svgData.paths.forEach((path, i) => {
            shape = path.toShapes(true)[0];
          });
          break;
        default:
          break;
      }

      const extrudeSettings = {
        steps: 1,
        depth: 1,
        bevelEnabled: false,
        bevelThickness: 1,
        bevelSize: 1,
        bevelOffset: 0,
        bevelSegments: 1,
      };

      const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
      // if (profile.indexOf("CURVE_") > -1) {
      //   geometry.translate(0, -dimensions.y / 2 - 0.001, 0);
      // }
      geometry.scale(width, dimensions.y, depth);

      geometry.computeBoundingBox();

      if (side === "L") {
        geometry.translate(-width / 2, 0, -depth / 2);
        geometry.rotateY(-Math.PI / 2);
      } else if (side === "R") {
        geometry.translate(-width / 2, 0, -depth / 2);
        geometry.rotateY(Math.PI / 2);
      } else if (side === "T") {
        geometry.translate(-width / 2, 0, -depth / 2);
      } else if (side === "B") {
        geometry.translate(-width / 2, 0, -depth / 2);
        geometry.rotateY(-Math.PI);
      }

      return geometry;
    },
    [boxArgs, dimensions.y]
  );

  const BoxFactory = useCallback(
    (side) => {
      let d = boxArgs.h;
      if (side === "T" || side === "B") {
        d = boxArgs.v;
      }
      let width = 1,
        // length = 1,
        depth = 1;
      if (side === "L" || side === "R") {
        width = boxArgs.h[2];
        // length = boxArgs.v[0];
        depth = boxArgs.h[0];
      } else if (side === "T" || side === "B") {
        width = boxArgs.h[2];
        // length = boxArgs.v[0];
        depth = boxArgs.v[2];
      }

      const geometry = new THREE.BoxBufferGeometry(d[2], d[1], d[0]);
      geometry.translate(d[2] / 2, d[1] / 2, d[0] / 2);
      geometry.computeBoundingBox();

      if (side === "L") {
        geometry.translate(-width / 2, 0, -depth / 2);
        geometry.rotateY(-Math.PI / 2);
      } else if (side === "R") {
        geometry.translate(-width / 2, 0, -depth / 2);
        geometry.rotateY(Math.PI / 2);
      } else if (side === "T") {
        geometry.translate(-depth / 2, 0, -width / 2);
        geometry.rotateY(Math.PI / 2);
      } else if (side === "B") {
        geometry.translate(-depth / 2, 0, -width / 2);
        geometry.rotateY(-Math.PI / 2);
      }

      return geometry;
    },
    [boxArgs]
  );

  const EdgeFactory = useCallback(
    (side) => {
      let geo;
      switch (true) {
        case profile === "CURVE":
        case profile.indexOf("CURVE_") > -1:
          geo = CurveFactory(side, profile);
          break;
        case "BOX":
        default:
          geo = BoxFactory(side);
          break;
      }
      return geo;
    },
    [profile, CurveFactory, BoxFactory]
  );

  const isCurve = useMemo(() => {
    return profile.indexOf("CURVE") > -1;
  }, [profile]);

  const frameY = useMemo(() => {
    return -dimensions.y + 0.001 + surface;
  }, [dimensions.y, surface]);

  const materialsLeft = useMemo(() => {
    const m = new THREE.MeshMatcapMaterial({
      color: color,
      map: textureMap,
      matcap: matcap,
      clippingPlanes: clippingPlanes.left,
    });
    if (isCurve) return m;
    const m2 = new THREE.MeshMatcapMaterial({
      color: color,
      map: textureMap2,
      matcap: matcap,
      clippingPlanes: clippingPlanes.left,
    });
    return [m2, m2, m, m, m, m];
  }, [isCurve, color, textureMap, textureMap2, matcap, clippingPlanes.left]);

  const materialsRight = useMemo(() => {
    const m = new THREE.MeshMatcapMaterial({
      color: color,
      map: textureMap,
      matcap: matcap,
      clippingPlanes: clippingPlanes.right,
    });
    if (isCurve) return m;
    const m2 = new THREE.MeshMatcapMaterial({
      color: color,
      map: textureMap2,
      matcap: matcap,
      clippingPlanes: clippingPlanes.right,
    });
    return [m2, m2, m, m, m, m];
  }, [isCurve, color, textureMap, textureMap2, matcap, clippingPlanes.right]);

  const materialsTop = useMemo(() => {
    const m = new THREE.MeshMatcapMaterial({
      color: color,
      map: isCurve ? textureMap : textureMap2,
      matcap: matcap,
      side: THREE.DoubleSide,
      clippingPlanes: clippingPlanes.top,
    });
    return m;
  }, [isCurve, textureMap, textureMap2, matcap, clippingPlanes, color]);

  const materialsBottom = useMemo(() => {
    const m = new THREE.MeshMatcapMaterial({
      color: color,
      map: isCurve ? textureMap : textureMap2,
      matcap: matcap,
      side: THREE.DoubleSide,
      clippingPlanes: clippingPlanes.bottom,
    });
    return m;
  }, [isCurve, textureMap, textureMap2, matcap, clippingPlanes, color]);

  return (
    <group>
      {/* <mesh
        geometry={new THREE.BoxBufferGeometry(10, 40, 40)}
        material={new THREE.MeshPhysicalMaterial({ map: textureMap3 })}
      /> */}
      {/* {clippingPlanes.left.map((p, i) => (
        <primitive
          key={`clip_${i}`}
          object={new THREE.PlaneHelper(p, 10, 0xff9900)}
        />
      ))} */}
      <group position-y={frameY}>
        <mesh
          position-z={-bounds[2] / 2 + dimensions.x / 2}
          geometry={EdgeFactory("L")}
          material={materialsLeft}
          castShadow={true}
          receiveShadow={true}
        />
        <mesh
          position-z={bounds[2] / 2 - dimensions.x / 2}
          geometry={EdgeFactory("R")}
          material={materialsRight}
          castShadow={true}
          receiveShadow={true}
        />
        <mesh
          position-x={-bounds[0] / 2 + dimensions.x / 2}
          geometry={EdgeFactory("T")}
          material={materialsTop}
          castShadow={true}
          receiveShadow={true}
        />
        <mesh
          position-x={bounds[0] / 2 - dimensions.x / 2}
          geometry={EdgeFactory("B")}
          material={materialsBottom}
          castShadow={true}
          receiveShadow={true}
        />
      </group>
    </group>
  );
};

export default SimpleBoxLayer;
