import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Campaigns from "./pages/Campaigns";
import Viewer from "./pages/Viewer";
const Editor = React.lazy(() => import("./pages/Editor"));

const App = () => {
  console.log("version 0.6.3");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/campaigns/" element={<Campaigns />}>
          <Route path="archan" element={<Viewer campaign={"ARCHAN"} />} />
          <Route
            path="archan/editor"
            element={<Editor campaign={"ARCHAN"} />}
          />
          <Route
            path="archan-prana"
            element={<Viewer campaign={"ARCHAN-PRANA"} />}
          />
          <Route
            path="archan-prana/editor"
            element={<Editor campaign={"ARCHAN-PRANA"} />}
          />
          <Route
            path="archan-day"
            element={<Viewer campaign={"ARCHAN-DAY"} />}
          />
          <Route
            path="archan-day/editor"
            element={<Editor campaign={"ARCHAN-DAY"} />}
          />
          <Route
            path="archan-night"
            element={<Viewer campaign={"ARCHAN-NIGHT"} />}
          />
          <Route
            path="archan-night/editor"
            element={<Editor campaign={"ARCHAN-NIGHT"} />}
          />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Campaign not found.</p>
              </main>
            }
          />
        </Route>
        <Route path="/editor" element={<Editor />} />
        <Route path="/" element={<Viewer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
