const clonex6 = {
  "orientation=SQUARE": "",
  orientation: "SQUARE",
  custom_size: { x: 50, y: 50 },
  nft_fit: "COVER",
  nft_padding: 0,
  nft_surface: 0.03,
  backsideMatcap: "F75F0B_461604_9A3004_FB9D2F",
  backsideColor: "#ebaeae",
  backsideTextureId: "",
  lights: 0,
  style_1: "FLOW",
  dimensions_1: { x: 3.3, y: 0.1 },
  matCapId_1: "E6BF3C_5A4719_977726_FCFC82",
  color_1: "#ffffff",
  textureId_1: "",
  surface_1: -0.15,
  profile_1: "CURVE_5",
  cartouche_1: "cartouche_01.fbx",
  cartoucheOffset_1: 0,
  cartoucheSides_1: 1,
  cartoucheFlip_1: false,
  cartoucheNudge_1: { x: 0, y: 0, z: 0 },
  echoLayout_1: "straight",
  echoMesh_1: "box",
  echoConfig_1: { x: 1, y: 1, z: 1 },
  repeat_1: 1,
  offset_1: 0,
  scale_1: { x: 1, y: 1, z: 1 },
  flowThickness_1: 0.01,
  flowRepeat_1: 5,
  flowRevolutions_1: 1,
  flowColor_1: "#ffffff",
  flowNoiseAmp_1: 0,
  flowOffset_1: 0.9,
  flowScale_1: 0.9500000000000001,
  frameId_1: "frame_01.fbx",
  style_2: "SIMPLE_BOX",
  dimensions_2: { x: 2.800000000000001, y: 1.6000000000000008 },
  matCapId_2: "F75F0B_461604_9A3004_FB9D2F",
  color_2: "#ffeeee",
  textureId_2: "",
  surface_2: 0.05,
  profile_2: "BOX",
  cartouche_2: "cartouche_01.fbx",
  cartoucheOffset_2: 0,
  cartoucheSides_2: 1,
  cartoucheFlip_2: false,
  cartoucheNudge_2: { x: 0, y: 0, z: 0 },
  echoLayout_2: "straight",
  echoMesh_2: "box",
  echoConfig_2: { x: 1, y: 1, z: 1 },
  repeat_2: 1,
  offset_2: 0,
  scale_2: { x: 1, y: 1, z: 1 },
  flowThickness_2: 0.4,
  flowRepeat_2: 1,
  flowRevolutions_2: 0,
  flowColor_2: "#ffffff",
  flowNoiseAmp_2: 0,
  flowOffset_2: 0,
  flowScale_2: 1,
  frameId_2: "frame_01.fbx",
  style_3: "CARTOUCHE",
  dimensions_3: { x: 1.8, y: 2 },
  matCapId_3: "F75F0B_461604_9A3004_FB9D2F",
  color_3: "#ffeeee",
  textureId_3: "",
  surface_3: 0.01,
  profile_3: "CURVE_5",
  cartouche_3: "cartouche_03.fbx",
  cartoucheOffset_3: -1.3,
  cartoucheSides_3: 5,
  cartoucheFlip_3: false,
  cartoucheNudge_3: { x: 0, y: 0, z: 0 },
  echoLayout_3: "straight",
  echoMesh_3: "box",
  echoConfig_3: { x: 1, y: 1, z: 1 },
  repeat_3: 1,
  offset_3: 0,
  scale_3: { x: 1, y: 1, z: 1 },
  flowThickness_3: 0.4,
  flowRepeat_3: 1,
  flowRevolutions_3: 0,
  flowColor_3: "#ffffff",
  flowNoiseAmp_3: 0,
  flowOffset_3: 0,
  flowScale_3: 1,
  frameId_3: "frame_01.fbx",
  style_4: "SIMPLE_BOX",
  dimensions_4: { x: 1.9999999999999998, y: 0.1 },
  matCapId_4: "D0CCCB_524D50_928891_727581",
  color_4: "#ffffff",
  textureId_4: "",
  surface_4: 0.01,
  profile_4: "BOX",
  cartouche_4: "cartouche_01.fbx",
  cartoucheOffset_4: 0,
  cartoucheSides_4: 1,
  cartoucheFlip_4: false,
  cartoucheNudge_4: { x: 0, y: 0, z: 0 },
  echoLayout_4: "straight",
  echoMesh_4: "box",
  echoConfig_4: { x: 1, y: 1, z: 1 },
  repeat_4: 1,
  offset_4: 0,
  scale_4: { x: 1, y: 1, z: 1 },
  flowThickness_4: 0.4,
  flowRepeat_4: 1,
  flowRevolutions_4: 0,
  flowColor_4: "#ffffff",
  flowNoiseAmp_4: 0,
  flowOffset_4: 0,
  flowScale_4: 1,
  frameId_4: "frame_01.fbx",
  style_5: "SIMPLE_BOX",
  dimensions_5: { x: 0.2, y: 0.1 },
  matCapId_5: "E6BF3C_5A4719_977726_FCFC82",
  color_5: "#ffffff",
  textureId_5: "",
  surface_5: 0.01,
  profile_5: "BOX",
  cartouche_5: "cartouche_01.fbx",
  cartoucheOffset_5: 0,
  cartoucheSides_5: 1,
  cartoucheFlip_5: false,
  cartoucheNudge_5: { x: 0, y: 0, z: 0 },
  echoLayout_5: "straight",
  echoMesh_5: "box",
  echoConfig_5: { x: 1, y: 1, z: 1 },
  repeat_5: 1,
  offset_5: 0,
  scale_5: { x: 1, y: 1, z: 1 },
  flowThickness_5: 0.4,
  flowRepeat_5: 1,
  flowRevolutions_5: 0,
  flowColor_5: "#ffffff",
  flowNoiseAmp_5: 0,
  flowOffset_5: 0,
  flowScale_5: 1,
  frameId_5: "frame_01.fbx",
  style_6: "",
  dimensions_6: { x: 1, y: 1 },
  matCapId_6: "",
  color_6: "#ffffff",
  textureId_6: "",
  surface_6: 0.01,
  profile_6: "BOX",
  cartouche_6: "cartouche_01.fbx",
  cartoucheOffset_6: 0,
  cartoucheSides_6: 1,
  cartoucheFlip_6: false,
  cartoucheNudge_6: { x: 0, y: 0, z: 0 },
  echoLayout_6: "straight",
  echoMesh_6: "box",
  echoConfig_6: { x: 1, y: 1, z: 1 },
  repeat_6: 1,
  offset_6: 0,
  scale_6: { x: 1, y: 1, z: 1 },
  flowThickness_6: 0.4,
  flowRepeat_6: 1,
  flowRevolutions_6: 0,
  flowColor_6: "#ffffff",
  flowNoiseAmp_6: 0,
  flowOffset_6: 0,
  flowScale_6: 1,
  frameId_6: "frame_01.fbx",
  grid: false,
  shadow: false,
  background: false,
  backgroundColor: "#ffffff",
  orbitStyle: "ORBIT_FRONT",
  orbitSpeed: "SLOW",
  backLogo: "/img/frahm_sample.svg",
  campaignLogo: "",
};
export default clonex6;
