const Rusticus = {
  orientation: "LANDSCAPE",
  nft_fit: "CONTAIN",
  nft_padding: 0,
  nft_surface: 0.03,
  backsideMatcap: "",
  backsideColor: "#ffffff",
  backsideTextureId: "",
  lights: 0,
  style_1: "SIMPLE_BOX",
  dimensions_1: { x: 2.1999999999999997, y: 1 },
  matCapId_1: "CCC5C9_3B2B2B_67585B_7F7375",
  color_1: "#453838",
  textureId_1: "/img/diffuse/002-diffuse.jpg",
  surface_1: 0.01,
  profile_1: "BOX",
  cartouche_1: "cartouche_01.fbx",
  cartoucheOffset_1: 0,
  cartoucheSides_1: 1,
  echoLayout_1: "straight",
  echoMesh_1: "box",
  echoConfig_1: { x: 1, y: 1, z: 1 },
  repeat_1: 1,
  offset_1: 0,
  scale_1: { x: 1, y: 1, z: 1 },
  frameId_1: "frame_01.fbx",
  style_2: "CARTOUCHE",
  dimensions_2: { x: 2, y: 1.3999999999999997 },
  matCapId_2: "48270F_C4723B_9B5728_7B431B",
  color_2: "#6c6363",
  textureId_2: "/img/diffuse/010-diffuse.jpg",
  surface_2: 0.01,
  profile_2: "BOX",
  cartouche_2: "cartouche_02.fbx",
  cartoucheOffset_2: 2.2,
  cartoucheSides_2: 5,
  echoLayout_2: "straight",
  echoMesh_2: "box",
  echoConfig_2: { x: 1, y: 1, z: 1 },
  repeat_2: 1,
  offset_2: 0,
  scale_2: { x: 1, y: 1, z: 1 },
  frameId_2: "frame_01.fbx",
  style_3: "SIMPLE_BOX",
  dimensions_3: { x: 0.5, y: 1 },
  matCapId_3: "CCC5C9_3B2B2B_67585B_7F7375",
  color_3: "#453838",
  textureId_3: "/img/diffuse/002-diffuse.jpg",
  surface_3: 0.01,
  profile_3: "CURVE_5",
  cartouche_3: "cartouche_01.fbx",
  cartoucheOffset_3: 0,
  cartoucheSides_3: 1,
  echoLayout_3: "straight",
  echoMesh_3: "sphere",
  echoConfig_3: { x: 3.5, y: 2.5, z: -2 },
  repeat_3: 1.09,
  offset_3: -4,
  scale_3: { x: 1, y: 1, z: 1 },
  frameId_3: "frame_01.fbx",
  style_4: "",
  dimensions_4: { x: 1, y: 1 },
  matCapId_4: "",
  color_4: "#ffffff",
  textureId_4: "",
  surface_4: 0.01,
  profile_4: "BOX",
  cartouche_4: "cartouche_01.fbx",
  cartoucheOffset_4: 0,
  cartoucheSides_4: 1,
  echoLayout_4: "straight",
  echoMesh_4: "box",
  echoConfig_4: { x: 1, y: 1, z: 1 },
  repeat_4: 1,
  offset_4: 0,
  scale_4: { x: 1, y: 1, z: 1 },
  frameId_4: "frame_01.fbx",
  style_5: "",
  dimensions_5: { x: 1, y: 1 },
  matCapId_5: "",
  color_5: "#ffffff",
  textureId_5: "",
  surface_5: 0.01,
  profile_5: "BOX",
  cartouche_5: "cartouche_01.fbx",
  cartoucheOffset_5: 0,
  cartoucheSides_5: 1,
  echoLayout_5: "straight",
  echoMesh_5: "box",
  echoConfig_5: { x: 1, y: 1, z: 1 },
  repeat_5: 1,
  offset_5: 0,
  scale_5: { x: 1, y: 1, z: 1 },
  frameId_5: "frame_01.fbx",
  grid: false,
  shadow: false,
  background: false,
  backgroundColor: "#ffffff",
  orbitStyle: "ORBIT_FRONT",
  orbitSpeed: "SLOW",
};
export default Rusticus;
