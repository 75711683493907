import React, { useLayoutEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three";

import usePrevious from "../hooks/usePrevious";

const OrbitController = React.forwardRef(
  (
    { isOrbitting, orbitStyle = "SIDE_TO_SIDE", orbitSpeed = "NORMAL" },
    ref
  ) => {
    useLayoutEffect(() => {
      // store initial state on load so reset restores properly
      ref.current.saveState();
    }, [ref]);

    const prevIsOrbitting = usePrevious(isOrbitting);
    const [needsReset, setNeedsReset] = useState(false);
    const [timer, setTimer] = useState(0);

    useFrame(({ camera }) => {
      let t = timer;
      if (isOrbitting && !prevIsOrbitting) {
        setTimer(0);
        t = 0;
        setNeedsReset(true);
      }

      if (needsReset && !isOrbitting && prevIsOrbitting) {
        ref.current.reset();
        camera.position.x = 0;
        camera.position.y = 0;
        camera.position.z = 65;
        ref.current.target.x = 0;
        ref.current.target.y = 0;
        ref.current.target.z = 0;
        camera.rotation.x = 0;
        camera.rotation.y = 0;
        camera.rotation.z = 0;
        // camera.rotation.y = 0;
        camera.updateProjectionMatrix();
        setNeedsReset(false);
      }

      const yAxis = new THREE.Vector3(0, 1, 0);
      yAxis.normalize();

      const quaternion = new THREE.Quaternion();
      let tm = orbitSpeed === "SLOW" ? 0.01 : 0.04;
      let delta = orbitSpeed === "SLOW" ? 1 : 4;
      let td = t * delta;
      let angle = orbitSpeed === "SLOW" ? 0.015 : 0.06;

      if (isOrbitting) {
        if (orbitStyle === "ORBIT_FRONT") {
          camera.position.applyQuaternion(
            quaternion.setFromAxisAngle(yAxis, Math.sin(td - Math.PI / 2) * tm)
          );
          camera.lookAt(new THREE.Vector3());
          camera.updateProjectionMatrix();
        } else if (orbitStyle === "FLYOVER") {
          camera.position.x = Math.cos(td) * 10;
          camera.position.y = Math.cos(td) * 20;
          ref.current.target.x = Math.cos(td) * 5;
          ref.current.target.y = Math.cos(td) * 5;
          camera.lookAt(new THREE.Vector3());
          camera.updateProjectionMatrix();
        } else if (orbitStyle === "VERTICAL") {
          camera.position.x = -20;
          camera.position.y = Math.cos(td) * -25;
          camera.position.z = 20;
          ref.current.target.x = -20;
          ref.current.target.y = Math.cos(td) * -22;
          ref.current.target.z = -20;
        } else if (orbitStyle === "FIGURE_8") {
          camera.position.x = Math.cos(td) * -25;
          camera.position.y = Math.sin(td * 2) * -10;
          // camera.position.z = 65 - Math.cos(td) * 10;
          ref.current.target.x = Math.cos(td) * -22;
          ref.current.target.y = Math.sin(td * 2) * -10;
          // ref.current.target.z = -65 - Math.cos(t) * 10;
          camera.lookAt(new THREE.Vector3());
          camera.updateProjectionMatrix();
        } else if (orbitStyle === "ZOOM") {
          camera.position.z = 65 - Math.sin(td) * 30;
          ref.current.target.z = -65;
        } else if (orbitStyle === "ORBIT_FULL") {
          camera.position.applyQuaternion(
            quaternion.setFromAxisAngle(yAxis, angle)
          );
          camera.lookAt(new THREE.Vector3());
          camera.updateProjectionMatrix();
        } else if (orbitStyle === "ORBIT_TO_CENTER") {
          if (td < Math.PI * 2) {
            camera.position.applyQuaternion(
              quaternion.setFromAxisAngle(yAxis, Math.cos(td) * angle)
            );
          } else if (td >= Math.PI * 2) {
            camera.position.applyQuaternion(
              quaternion.setFromAxisAngle(yAxis, 0)
            );
          }
          camera.lookAt(new THREE.Vector3());
          camera.updateProjectionMatrix();
        }

        setTimer(t + 0.018);
      }
    });

    return (
      <OrbitControls
        ref={ref}
        // enabled={!isOrbitting}
        enableDamping={true}
        dampingFactor={0.18}
        // autoRotate={isOrbitting}
        // autoRotateSpeed={4}
      />
    );
  }
);

export default OrbitController;
