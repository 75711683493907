const CURVE_2 = `<?xml version="1.0" encoding="utf-8"?>
          <!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 1 1" style="enable-background:new 0 0 1 1;" xml:space="preserve">
          <path d="M1,1H0V0h0.10169C0.5,0,0.48305,0.88559,1,0.88559V1z"/>
          </svg>
          `;

const CURVE_3 = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1 1" style="enable-background:new 0 0 1 1;" xml:space="preserve">
<path d="M1,1H0V0h0.09v0.21H0.3V0h0.2C0.9,0,0.77,0.89,1,0.89V1z"/>
</svg>`;

const CURVE_4 = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1 1" style="enable-background:new 0 0 1 1;" xml:space="preserve">
  <path class="st2" d="M1,1H0V0h0.2v0.5h0.3V0h0.2C1,0.3,0.5,0.4,1,0.9L1,1L1,1z"/>
</svg>
`;

const CURVE_5 = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1001 1001" style="enable-background:new 0 0 1001 1001;" xml:space="preserve">
   <path class="st2" d="M1,1H0.4L0,0h0.2v0.1l0.3,0.1V0h0.2C1,0.3,0.5,0.4,1,0.9V1L1,1z"/>
   </svg>
`;

export { CURVE_2, CURVE_3, CURVE_4, CURVE_5 };
