import { useMemo } from "react";
import * as THREE from "three";

const useClippingPlanes = (
  x = 1,
  y = 1,
  width,
  height,
  manualOffset = 0,
  backOffset = 0.5
) => {
  const clippingPlanes = useMemo(() => {
    const offset = manualOffset + (height - width) / 4;
    const p1 = new THREE.Plane(new THREE.Vector3(x, y, 0), offset);
    const p2 = new THREE.Plane(new THREE.Vector3(-x, y, 0), offset);
    const p3 = new THREE.Plane(new THREE.Vector3(-x, -y, 0), offset);
    const p4 = new THREE.Plane(new THREE.Vector3(x, -y, 0), offset);
    const p5 = new THREE.Plane(new THREE.Vector3(x, y, 0), -offset);
    const p6 = new THREE.Plane(new THREE.Vector3(-x, y, 0), -offset);
    const p7 = new THREE.Plane(new THREE.Vector3(-x, -y, 0), -offset);
    const p8 = new THREE.Plane(new THREE.Vector3(x, -y, 0), -offset);

    const back = new THREE.Plane(new THREE.Vector3(0, 0, 1), backOffset);
    return {
      top: [p5, p6, back],
      bottom: [p7, p8, back],
      left: [p2, p3, back],
      right: [p1, p4, back],
    };
  }, [x, y, width, height, manualOffset, backOffset]);

  return clippingPlanes;
};

export default useClippingPlanes;
