import { useTexture } from "@react-three/drei";

function getFormatString(format) {
  switch (format) {
    case 64:
      return "-64px";
    case 128:
      return "-128px";
    case 256:
      return "-256px";
    case 512:
      return "-512px";
    default:
      return "";
  }
}

const MATCAP_ROOT = "/img/matcaps/";

export function useMatcapTexture(id = 0, format = 1024) {
  const DEFAULT_MATCAP = "blank";

  const fileName = `${id || DEFAULT_MATCAP}${getFormatString(format)}.png`;
  const url = `${MATCAP_ROOT}${fileName}`;

  const matcapTexture = useTexture(url);

  return [matcapTexture, url];
}
