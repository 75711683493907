const Agamemnon = {
  orientation: "PORTRAIT",
  nft_fit: "CONTAIN",
  nft_padding: 0,
  nft_surface: 0.03,
  lights: 0,
  style_1: "PASSEPARTOUT",
  dimensions_1: { x: 1, y: 1 },
  matCapId_1: "DFDFCA_4D2D07_6B5224_857145",
  color_1: "#ffffff",
  textureId_1: "",
  surface_1: 0.01,
  profile_1: "BOX",
  cartouche_1: "cartouche_01.fbx",
  cartoucheOffset_1: 0,
  cartoucheSides_1: 1,
  echoLayout_1: "straight",
  echoMesh_1: "box",
  echoConfig_1: { x: 1, y: 1, z: 1 },
  repeat_1: 1,
  offset_1: 0,
  scale_1: { x: 1, y: 1, z: 1 },
  frameId_1: "frame_01.fbx",
  style_2: "SIMPLE_BOX",
  dimensions_2: { x: 3.1, y: 1.7000000000000006 },
  matCapId_2: "DFDFCA_4D2D07_6B5224_857145",
  color_2: "#ffffff",
  textureId_2: "/img/diffuse/004-diffuse.jpg",
  surface_2: 0.11,
  profile_2: "CURVE_3",
  cartouche_2: "cartouche_01.fbx",
  cartoucheOffset_2: 0,
  cartoucheSides_2: 1,
  echoLayout_2: "straight",
  echoMesh_2: "box",
  echoConfig_2: { x: 1, y: 1, z: 1 },
  repeat_2: 1,
  offset_2: 0,
  scale_2: { x: 1, y: 1, z: 1 },
  frameId_2: "frame_01.fbx",
  style_3: "",
  dimensions_3: { x: 1.1000000000000014, y: 1.4999999999999998 },
  matCapId_3: "E6BF3C_5A4719_977726_FCFC82",
  color_3: "#ffffff",
  textureId_3: "",
  surface_3: 0.11,
  profile_3: "CURVE",
  cartouche_3: "cartouche_01.fbx",
  cartoucheOffset_3: 0,
  cartoucheSides_3: 1,
  echoLayout_3: "straight",
  echoMesh_3: "box",
  echoConfig_3: { x: 1, y: 1, z: 1 },
  repeat_3: 1,
  offset_3: 0,
  scale_3: { x: 1, y: 1, z: 1 },
  frameId_3: "frame_01.fbx",
  style_4: "",
  dimensions_4: { x: 1, y: 1 },
  matCapId_4: "gold_0",
  color_4: "#ffffff",
  textureId_4: "",
  surface_4: 0.11,
  profile_4: "BOX",
  cartouche_4: "cartouche_01.fbx",
  cartoucheOffset_4: 0,
  cartoucheSides_4: 1,
  echoLayout_4: "straight",
  echoMesh_4: "box",
  echoConfig_4: { x: 1, y: 1, z: 1 },
  repeat_4: 1,
  offset_4: 0,
  scale_4: { x: 1, y: 1, z: 1 },
  frameId_4: "frame_01.fbx",
  style_5: "",
  dimensions_5: { x: 1, y: 1 },
  matCapId_5: "gold_0",
  color_5: "#ffffff",
  textureId_5: "",
  surface_5: 0.11,
  profile_5: "BOX",
  cartouche_5: "cartouche_01.fbx",
  cartoucheOffset_5: 0,
  cartoucheSides_5: 1,
  echoLayout_5: "straight",
  echoMesh_5: "box",
  echoConfig_5: { x: 1, y: 1, z: 1 },
  repeat_5: 1,
  offset_5: 0,
  scale_5: { x: 1, y: 1, z: 1 },
  frameId_5: "frame_01.fbx",
  grid: false,
  shadow: false,
  background: false,
  backgroundColor: "#ffffff",
  orbitStyle: "SIDE_TO_SIDE",
};

export default Agamemnon;
