import { useRef, useMemo, useCallback } from "react";
import * as THREE from "three";
import SimplexNoise from "simplex-noise";
import { useCustomCompareMemo } from "use-custom-compare";
import isEqual from "lodash/isEqual";

import { useMatcapTexture } from "../hooks/useMatcapTexture";
import useClippingPlanes from "../hooks/useClippingPlanes";
import { useDiscreetTexture } from "../hooks/useDiscreetTexture";

const FlowEdge = ({
  side,
  matCapId,
  color,
  dimensions,
  bounds,
  repeat,
  offset,
  // scale,
  surface,
  clippingPlanes,
  textureId,
  prevDimensions,
  imageSize,
  revolutions,
  seed = "ARCHAN",
  color2,
  noiseAmp,
  thickness,
  flowScale,
  ...props
} = {}) => {
  const meshRef = useRef();
  const [matcap] = useMatcapTexture(matCapId);
  const textureMap = useDiscreetTexture(textureId, 0.005, 0.1);
  const textureMap2 = useDiscreetTexture(textureId, 0.1, 0.005);
  const simplex = useMemo(() => new SimplexNoise(seed), [seed]);

  const computeCurl = useCallback(
    (x, y) => {
      var eps = 0.0001;

      //Find rate of change in X direction
      var n1 = simplex.noise2D(x + eps, y);
      var n2 = simplex.noise2D(x - eps, y);

      //Average to find approximate derivative
      var a = (n1 - n2) / (2 * eps);

      //Find rate of change in Y direction
      n1 = simplex.noise2D(x, y + eps);
      n2 = simplex.noise2D(x, y - eps);

      //Average to find approximate derivative
      var b = (n1 - n2) / (2 * eps);

      //Curl
      return [b, -a];
    },
    [simplex]
  );

  const geometries = useCustomCompareMemo(
    () => {
      const geos = [];
      let w, h;
      if (side === "T" || side === "B") {
        w = imageSize[0];
        h = imageSize[1];
      } else {
        w = imageSize[1];
        h = imageSize[0];
      }

      // triangle that tapers in towards the viewer
      const stamp = new THREE.Shape();
      stamp.moveTo(thickness, 0.2);
      stamp.lineTo(0, 0);
      stamp.lineTo(0, 0.2);

      const v = new THREE.Vector3();
      const columns = Math.floor(repeat);
      const steps = 50;
      const amp = [1, 1];
      for (var x = 0, rx; x < columns; x++) {
        const points = [];
        for (var y = 0, ry, cornerFactor; y <= steps; y++) {
          rx = (x + 1) / (columns + 1);
          ry = y / steps;
          cornerFactor = 1;
          if (ry < 0.1) {
            cornerFactor = ry / 0.1;
          }
          if (ry > 0.9) {
            cornerFactor = (1 - ry) / 0.1;
          }
          const n = [];
          n[0] = Math.sin(ry * Math.PI * 2 * revolutions) * dimensions.y * 0.25;
          n[1] = Math.cos(ry * Math.PI * 2 * revolutions) * dimensions.x * 0.25;

          const c = computeCurl(rx + offset * 0.1, ry + offset * 0.1);
          // const c = [
          //   simplex.noise2D(rx, ry),
          //   simplex.noise2D(x * 0.01, y * 0.01),
          // ];
          n[0] += c[0] * noiseAmp * 0.25;
          n[1] += c[1] * noiseAmp * 0.25;

          // if (side === "T" || side === "R") n[1] *= -1;

          let vx = ry * w - w / 2;
          if (ry === 0) {
            vx = -w / 2;
          } else if (ry === 1) {
            vx = w / 2;
          }
          points.push(
            v.clone().set(
              // ry * w - w / 2 + rx * dimensions.x * 2,
              vx,
              n[0] * amp[1] * dimensions.y * cornerFactor,
              n[1] * amp[0] * dimensions.x * rx * cornerFactor
            )
          );
        }

        const extrudePath = new THREE.CatmullRomCurve3(points);

        const extrudeSettings = {
          steps: steps * 4,
          depth: 2,
          bevelEnabled: true,
          bevelThickness: 2,
          bevelSize: 1,
          bevelOffset: 0,
          bevelSegments: 4,
          extrudePath: extrudePath,
        };

        const g = new THREE.ExtrudeGeometry(stamp, extrudeSettings);
        g.center();

        // g.rotateX((-Math.PI / 2) * rx);
        g.rotateY(-Math.PI / 2);
        g.translate(-h / 2 + 1, -2, 0);

        geos.push(g);
      }

      let g = geos[0];
      let tempColor = new THREE.Color(color);
      let tempColor2 = new THREE.Color(color2);
      let count = g.getAttribute("position").count;
      let p = g.getAttribute("position").array;
      let colors = [];
      for (let i = 0, r; i < count; i++) {
        switch (side) {
          case "T":
            r = 0;
            break;
          case "B":
            r = 1;
            break;
          case "L":
            r = 1 - (p[i * 3 + 2] + w / 2) / w;
            break;
          case "R":
            r = (p[i * 3 + 2] + w / 2) / w;
            break;
          default:
            break;
        }
        colors.push(...tempColor.clone().lerp(tempColor2, r).toArray());
      }

      for (var i = 0; i < geos.length; i++) {
        geos[i].setAttribute(
          "color",
          new THREE.Float32BufferAttribute(colors, 3)
        );
      }

      return geos;
    },
    [
      side,
      imageSize,
      dimensions.x,
      dimensions.y,
      repeat,
      revolutions,
      color,
      computeCurl,
      color2,
      offset,
      noiseAmp,
      thickness,
    ],
    (prevDeps, nextDeps) => {
      const e = isEqual(prevDeps, nextDeps);
      return e;
    }
  );

  return geometries.map((g, i) => {
    return (
      <mesh
        scale={[flowScale, 1, flowScale]}
        key={`geo_${i}`}
        ref={meshRef}
        receiveShadow={true}
        castShadow={true}
        geometry={g}
        {...props}
      >
        <meshMatcapMaterial
          matcap={matcap}
          color={0xffffff}
          map={side === "T" || side === "L" ? textureMap2 : textureMap}
          clippingPlanes={clippingPlanes}
          vertexColors={true}
        />
      </mesh>
    );
  });
};

const FlowLayer = ({ index, imageSize, surface, ...props } = {}) => {
  const clippingPlanes = useClippingPlanes(
    1,
    1,
    imageSize[0],
    imageSize[1],
    0,
    -0.05
  );
  return (
    <group position-y={-surface * 10}>
      <FlowEdge
        side="T"
        clippingPlanes={clippingPlanes.top}
        imageSize={imageSize}
        {...props}
      />
      <FlowEdge
        side="B"
        rotation-y={Math.PI}
        clippingPlanes={clippingPlanes.bottom}
        imageSize={imageSize}
        {...props}
      />
      <FlowEdge
        side="L"
        rotation-y={(Math.PI / 2) * 3}
        clippingPlanes={clippingPlanes.left}
        imageSize={imageSize}
        {...props}
      />
      <FlowEdge
        side="R"
        rotation-y={Math.PI / 2}
        clippingPlanes={clippingPlanes.right}
        imageSize={imageSize}
        {...props}
      />
    </group>
  );
};

export default FlowLayer;
