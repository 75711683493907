import * as THREE from "three";
import { useEffect, useMemo } from "react";
import { useThree } from "@react-three/fiber";

const Background = ({
  width = 250,
  height = 250,
  shadow = false,
  lights = 0,
  backgroundVisible,
  backgroundColor,
} = {}) => {
  const { scene } = useThree();
  const mesh = useMemo(() => {
    const geometry = new THREE.PlaneGeometry(width, height);
    // geometry.rotateX(-Math.PI / 2);

    const material = new THREE.ShadowMaterial({
      side: THREE.DoubleSide,
      transparent: true,
      // color: "#ff9900",
      // wireframe: true,
    });
    material.opacity = 0.2;

    const plane = new THREE.Mesh(geometry, material);

    plane.receiveShadow = true;

    return plane;
  }, [width, height]);

  // const background = useMemo(() => {
  //   const geometry = new THREE.PlaneGeometry(width * 10, height * 10);
  //   // geometry.rotateX(-Math.PI / 2);

  //   const material = new THREE.MeshBasicMaterial({
  //     side: THREE.DoubleSide,
  //     transparent: false,
  //     color: backgroundColor,
  //     // wireframe: true,
  //   });
  //   const plane = new THREE.Mesh(geometry, material);

  //   return plane;
  // }, [width, height, backgroundColor]);

  useEffect(() => {
    if (backgroundVisible) {
      scene.background = new THREE.Color(backgroundColor);
    } else {
      scene.background = null;
    }
  }, [backgroundVisible, backgroundColor, scene]);

  return (
    <>
      <ambientLight args={[0xffffff, 1.5]} />
      <directionalLight
        position={[0, 1, 5]}
        intensity={lights}
        castShadow={true}
        shadow-camera-near={0.1}
        shadow-camera-far={250}
        shadow-camera-left={-250}
        shadow-camera-right={250}
        shadow-camera-top={250}
        shadow-camera-bottom={-250}
      />
      {shadow && (
        <primitive
          object={mesh}
          position-z={-0.5}
          // rotation={[(Math.PI / 4) * 3, -Math.PI / 2, 0]}
        />
      )}
      {/* {backgroundVisible && <primitive object={background} position-z={-10} />} */}
    </>
  );
};

export default Background;
