import { useRef, useMemo, useEffect } from "react";
import * as THREE from "three";

import { useMatcapTexture } from "../hooks/useMatcapTexture";
import useClippingPlanes from "../hooks/useClippingPlanes";
import { useDiscreetTexture } from "../hooks/useDiscreetTexture";

const EchoEdge = ({
  matCapId,
  color,
  dimensions,
  bounds,
  imageSize,
  repeat,
  offset,
  scale,
  clippingPlanes,
  textureId,
  echoLayout,
  echoMesh,
  echoConfig,
  prevDimensions,
  side,
  ...props
} = {}) => {
  const meshRef = useRef();
  const [matcap] = useMatcapTexture(matCapId);

  const textureMap = useDiscreetTexture(textureId, 1, 1);

  const count = useMemo(() => {
    // const xCount = Math.floor(repeat * 25);
    // const yCount = Math.floor(repeat * 25);

    const availableWidth = imageSize[0];
    const availableHeight = imageSize[1];

    const xCount = Math.floor(availableWidth * repeat);
    const yCount = Math.floor(availableHeight * repeat);

    return { x: xCount, y: yCount, total: xCount * 2 + yCount * 2 };
  }, [imageSize, repeat]);

  useEffect(() => {
    const tempObject = new THREE.Object3D();

    // const width = bounds.z;
    // const height = bounds.x;
    let id = 0;

    const s = new THREE.Vector3(
      -bounds[0] / 2 - offset,
      0,
      -bounds[2] / 2 - offset
    );
    const e = new THREE.Vector3(
      -bounds[0] / 2 - offset,
      0,
      bounds[2] / 2 + offset
    );

    if (side === "L" || side === "R") {
      s.x = -bounds[0] / 2 - offset + prevDimensions[0];
      s.z = -bounds[2] / 2 - offset + prevDimensions[0];
      e.x = -bounds[0] / 2 - offset + prevDimensions[0];
      e.z = bounds[2] / 2 + offset - prevDimensions[0];
    }

    let curve = new THREE.CatmullRomCurve3([s, e]);

    switch (echoLayout) {
      case "curve":
        const m = e.clone().sub(s.clone()).multiplyScalar(0.5);
        m.z += prevDimensions[0] / 2;
        const vectors = [];
        var N = 100;
        var rotations = echoConfig.y * Math.PI;
        // var dimensions = { x: 1, y: 0.5 };
        var diameter = echoConfig.z;
        var dx,
          dy,
          dz = 1 - prevDimensions[1];
        for (var i = 0, r; i <= N; i++) {
          r = i / N;
          dx = m.x - echoConfig.x + Math.cos(r * rotations) * diameter * r;
          dy = m.z + ((Math.sin(r * rotations) * prevDimensions[0]) / 2) * r;
          vectors.push(new THREE.Vector3(-dy, dz, dx));
        }
        for (i = N; i > 0; i--) {
          r = i / N;
          dx = m.x + echoConfig.x - Math.cos(r * rotations) * diameter * r;
          dy = m.z + ((Math.sin(r * rotations) * prevDimensions[0]) / 2) * r;
          vectors.push(new THREE.Vector3(-dy, dz, dx));
        }
        curve = new THREE.CatmullRomCurve3(vectors);
        // curve = new THREE.CatmullRomCurve3([
        //   new THREE.Vector3(
        //     -bounds[0] / 2 - offset,
        //     0,
        //     -bounds[2] / 2 - offset
        //   ),
        //   new THREE.Vector3(-bounds[0] / 2 - offset + 5, 0, 0),
        //   new THREE.Vector3(-bounds[0] / 2 - offset, 0, bounds[2] / 2 + offset),
        // ]);
        break;
      default:
        break;
    }

    const instanceCount = side === "T" || side === "B" ? count.x : count.y;
    const points = curve.getSpacedPoints(instanceCount - 1);
    const s2 = repeat / 2;

    for (let x = 0, p; x < instanceCount; x++) {
      p = points[x].clone();
      tempObject.position.set(p.x, p.y, p.z);

      const t = curve.getTangentAt(x / instanceCount);
      tempObject.rotation.set(0, t.x, 0);

      // var r = x / instanceCount;
      // tempObject.position.set(
      //   -bounds[0] / 2 - offset,
      //   0,
      //   bounds[2] * r - bounds[2] / 2 - offset + offset * r * 2
      // );
      // console.log("tempObject.position", tempObject.position);

      tempObject.scale
        .set(dimensions.x, dimensions.y, dimensions.x)
        .multiply(scale)
        .multiply(new THREE.Vector3(s2, s2, s2));

      tempObject.updateMatrix();
      meshRef.current.setMatrixAt(id, tempObject.matrix);
      id++;
    }

    meshRef.current.instanceMatrix.needsUpdate = true;
  }, [
    dimensions,
    bounds,
    repeat,
    count,
    offset,
    scale,
    echoLayout,
    prevDimensions,
    echoConfig,
    imageSize,
    side,
  ]);

  const meshGeometry = useMemo(() => {
    let mesh = new THREE.BoxBufferGeometry(1, 1, 1);
    switch (echoMesh) {
      case "sphere":
        mesh = new THREE.SphereBufferGeometry(0.5, 32);
        break;
      default:
        break;
    }

    return mesh;
  }, [echoMesh]);

  return (
    <instancedMesh
      ref={meshRef}
      args={[null, null, count.total]}
      castShadow={true}
      geometry={meshGeometry}
      {...props}
    >
      <meshMatcapMaterial
        matcap={matcap}
        color={color}
        map={textureMap}
        clippingPlanes={clippingPlanes}
      />
    </instancedMesh>
  );
};

const EchoLayer = ({ index, dimensions, bounds, imageSize, ...props } = {}) => {
  const frameY = useMemo(() => {
    return -dimensions.y + 0.001;
  }, [dimensions.y]);

  const clippingPlanes = useClippingPlanes(1, 1, imageSize[0], imageSize[1]);
  const hBounds = [imageSize[0], 0, imageSize[1]];

  return (
    <group position-y={frameY}>
      <EchoEdge
        dimensions={dimensions}
        side="T"
        bounds={bounds}
        clippingPlanes={clippingPlanes.top}
        imageSize={imageSize}
        {...props}
      />
      <EchoEdge
        rotation-y={Math.PI}
        side="B"
        dimensions={dimensions}
        bounds={bounds}
        clippingPlanes={clippingPlanes.bottom}
        imageSize={imageSize}
        {...props}
      />
      <EchoEdge
        rotation-y={(Math.PI / 2) * 3}
        side="L"
        dimensions={dimensions}
        bounds={hBounds}
        clippingPlanes={clippingPlanes.left}
        imageSize={imageSize}
        {...props}
      />
      <EchoEdge
        rotation-y={Math.PI / 2}
        side="R"
        dimensions={dimensions}
        bounds={hBounds}
        clippingPlanes={clippingPlanes.right}
        imageSize={imageSize}
        {...props}
      />

      {/* <mesh>
          <boxBufferGeometry args={[25, 25, 25]} />
          <meshStandardMaterial
            color={0xff9900}
            side={THREE.DoubleSide}
            clippingPlanes={clippingPlanes.right}
            // wireframe
          />
        </mesh> */}
    </group>
  );
};

export default EchoLayer;
