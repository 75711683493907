import { useMemo } from "react";
import { useFBX } from "@react-three/drei";
import * as THREE from "three";

import { useMatcapTexture } from "../hooks/useMatcapTexture";
import useClippingPlanes from "../hooks/useClippingPlanes";
import { useDiscreetTexture } from "../hooks/useDiscreetTexture";

const CartoucheLayer = ({
  index,
  color,
  matCapId,
  cartoucheId,
  cartoucheOffset,
  cartoucheSides,
  bounds,
  imageSize,
  dimensions,
  prevBounds,
  prevDimensions,
  textureId,
  surface,
  flip,
  nudge,
}) => {
  const textureMap = useDiscreetTexture(textureId, 10, 1);
  const textureMap2 = useDiscreetTexture(textureId, 1, 10);

  const [matcap] = useMatcapTexture(matCapId);

  const fbx = useFBX(`/models/${cartoucheId}`);

  const { position, scale } = useMemo(() => {
    if (fbx?.children?.length) {
      for (var i = 0; i < fbx.children.length; i++) {
        const c = fbx.children[i];

        if (c.type !== "Mesh") continue;

        c.geometry.computeBoundingBox();

        // derive scale
        const scaleX = 0.01 * prevBounds[0] * dimensions.x;
        const scaleY = 0.05 * prevDimensions[2] * dimensions.y;
        const scale = Math.min(scaleX, scaleY);

        // derive top position
        const position = [
          -prevBounds[0] / 2 - prevDimensions[0] / 2,
          -bounds[1] / 2,
          cartoucheOffset,
        ];

        return {
          position: position,
          scale: [scale, scale, scale],
        };
      }
    }
    return [1, 1, 1];
  }, [fbx, prevBounds, prevDimensions, dimensions, cartoucheOffset, bounds]);

  const clippingPlanes = useClippingPlanes(1, 1, imageSize[0], imageSize[1]);

  const { materialTop, materialBottom, materialLeft, materialRight } =
    useMemo(() => {
      const m = new THREE.MeshMatcapMaterial({
        color: color,
        map: textureMap,
        matcap: matcap,
        // clippingPlanes: clippingPlanes.top,
      });

      const materialTop = m.clone();
      materialTop.map = textureMap2;
      materialTop.clippingPlanes = clippingPlanes.top;

      const materialBottom = m.clone();
      materialBottom.map = textureMap2;
      materialBottom.clippingPlanes = clippingPlanes.bottom;

      const materialLeft = m.clone();
      materialLeft.clippingPlanes = clippingPlanes.left;

      const materialRight = m.clone();
      materialRight.clippingPlanes = clippingPlanes.right;

      return { materialTop, materialBottom, materialLeft, materialRight };
    }, [color, textureMap, textureMap2, matcap, clippingPlanes]);

  const {
    repeatConfig,
    translateTop,
    translateBottom,
    translateLeft,
    translateRight,
    visibilities,
  } = useMemo(() => {
    var repeatConfig = [1];
    var scaleConfig = [1];
    switch (cartoucheSides) {
      case 1:
        repeatConfig = [1];
        scaleConfig = [1];
        break;
      case 2:
      case 3:
        repeatConfig = [1];
        scaleConfig = [1];
        break;
      case 4:
        repeatConfig = [1, 2, 3];
        scaleConfig = [0.8, 1, 0.8];
        break;
      case 5:
        repeatConfig = [1, 2, 3, 4, 5];
        scaleConfig = [1, 0.75, 1, 0.75, 1];
        break;
      default:
        break;
    }

    const l = repeatConfig.length - 1;
    var r;

    const translateTop = repeatConfig.map((_, i) => {
      r = l > 1 ? i / l : 0.5;
      const _r = [Math.PI, flip ? Math.PI : 0, Math.PI / 2];
      const _s = [
        scale[0] * scaleConfig[i],
        scale[1] * scaleConfig[i],
        scale[2] * scaleConfig[i],
      ];
      const _p = [...position];
      _p[2] = -bounds[2] / 2 + r * bounds[2];

      if (l > 1) {
        if (i === 0) {
          _p[2] += cartoucheOffset;
        } else if (i === l) {
          _p[2] -= cartoucheOffset;
        }
      }

      // _p[0] -= nudge.y;
      // _p[1] -= nudge.z;
      // _p[2] -= nudge.x;

      return { rotation: _r, scale: _s, position: _p };
    });

    const translateBottom = repeatConfig.map((_, i) => {
      r = l > 1 ? i / l : 0.5;
      const _r = [-Math.PI, flip ? 0 : Math.PI, Math.PI / 2];
      const _s = [
        scale[0] * scaleConfig[i],
        scale[1] * scaleConfig[i],
        scale[2] * scaleConfig[i],
      ];
      const _p = [-position[0], position[1], -bounds[2] / 2 + r * bounds[2]];

      if (l > 1) {
        if (i === 0) {
          _p[2] += cartoucheOffset;
        } else if (i === l) {
          _p[2] -= cartoucheOffset;
        }
      }

      // _p[0] += nudge.y;
      // _p[1] -= nudge.z;
      // _p[2] += nudge.x;

      return { rotation: _r, scale: _s, position: _p };
    });

    const translateLeft = repeatConfig.map((_, i) => {
      r = l > 1 ? i / l : 0.5;
      const _r = [-Math.PI, flip ? -Math.PI / 2 : Math.PI / 2, Math.PI / 2];
      const _s = [
        scale[0] * scaleConfig[i],
        scale[1] * scaleConfig[i],
        scale[2] * scaleConfig[i],
      ];

      const _p = [
        -bounds[0] / 2 + r * bounds[0],
        position[1],
        -prevBounds[2] / 2 - prevDimensions[0] / 2,
      ];
      // const _p = [-position[0], position[1], -bounds[2] / 2 + r * bounds[2]];
      // const _p = [
      //   -prevBounds[0] / 2 - prevDimensions[0] / 2,
      //   -bounds[1] / 2,
      //   cartoucheOffset,
      // ];

      if (l > 1) {
        if (i === 0) {
          _p[0] += cartoucheOffset;
        } else if (i === l) {
          _p[0] -= cartoucheOffset;
        }
      }

      // _p[0] += nudge.x;
      // _p[1] -= nudge.z;
      // _p[2] -= nudge.y;

      return { rotation: _r, scale: _s, position: _p };
    });

    const translateRight = repeatConfig.map((_, i) => {
      r = l > 1 ? i / l : 0.5;
      const _r = [-Math.PI, flip ? Math.PI / 2 : -Math.PI / 2, Math.PI / 2];
      const _s = [
        scale[0] * scaleConfig[i],
        scale[1] * scaleConfig[i],
        scale[2] * scaleConfig[i],
      ];

      const _p = [
        -bounds[0] / 2 + r * bounds[0],
        position[1],
        prevBounds[2] / 2 + prevDimensions[0] / 2,
      ];

      if (l > 1) {
        if (i === 0) {
          _p[0] += cartoucheOffset;
        } else if (i === l) {
          _p[0] -= cartoucheOffset;
        }
      }

      // _p[0] -= nudge.x;
      // _p[1] -= nudge.z;
      // _p[2] += nudge.y;

      return { rotation: _r, scale: _s, position: _p };
    });

    const visibilities = {
      top: true,
      bottom: false,
      left: false,
      right: false,
    };
    switch (cartoucheSides) {
      case 2:
        visibilities.bottom = true;
        break;
      case 3:
      case 4:
      case 5:
        visibilities.bottom = true;
        visibilities.left = true;
        visibilities.right = true;
        break;
      default:
        break;
    }

    return {
      repeatConfig,
      scaleConfig,
      translateTop,
      translateBottom,
      translateLeft,
      translateRight,
      visibilities,
    };
  }, [
    cartoucheSides,
    position,
    scale,
    cartoucheOffset,
    bounds,
    prevBounds,
    prevDimensions,
    flip,
  ]);

  return (
    <group position={[0, 0, 0]}>
      {repeatConfig.map((_, i) => {
        return (
          <group key={`group_${i}`}>
            {/* TOP */}
            <group {...translateTop[i]}>
              {visibilities.top &&
                fbx.children.map((c, j) => {
                  return (
                    <mesh
                      {...c}
                      key={`mesh_top_${cartoucheId}_${j}`}
                      castShadow={true}
                      material={materialTop}
                    />
                  );
                })}
            </group>

            {/* BOTTOM */}
            <group {...translateBottom[i]}>
              {visibilities.bottom &&
                fbx.children.map((c, j) => {
                  return (
                    <mesh
                      {...c}
                      key={`mesh_bottom_${cartoucheId}_${j}`}
                      castShadow={true}
                      material={materialBottom}
                    />
                  );
                })}
            </group>

            {/* LEFT */}
            <group {...translateLeft[i]}>
              {visibilities.left &&
                fbx.children.map((c, j) => {
                  return (
                    <mesh
                      {...c}
                      key={`mesh_left_${cartoucheId}_${j}`}
                      castShadow={true}
                      material={materialLeft}
                    />
                  );
                })}
            </group>

            {/* RIGHT */}
            <group {...translateRight[i]}>
              {visibilities.right &&
                fbx.children.map((c, j) => {
                  return (
                    <mesh
                      {...c}
                      key={`mesh_right_${cartoucheId}_${j}`}
                      castShadow={true}
                      material={materialRight}
                    />
                  );
                })}
            </group>
          </group>
        );
      })}
    </group>
  );
};

export default CartoucheLayer;
