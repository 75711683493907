import { useMemo, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";

const MediaControls = ({ mediaUrl, media }) => {
  const isMobile = useIsMobile();
  // WARNING: Landmine.
  // Assumption is videos autoplay on desktop, not on mobile
  // const [isPlaying, setIsPlaying] = useState(!isMobile);
  const [isMute, setIsMute] = useState(true);

  const isVideo = useMemo(() => {
    return (
      mediaUrl &&
      (mediaUrl.indexOf(".mp4") > -1 || mediaUrl.indexOf(".mov") > -1)
    );
  }, [mediaUrl]);

  // const playAndUnmute = (media) => {
  //   if (!media) return null;
  //   if (!isPlaying) {
  //     try {
  //       media.play();
  //       media.muted = false;
  //       setIsPlaying(true);
  //       setIsMute(false);
  //     } catch (e) {
  //       console.warn(e);
  //     }
  //   } else {
  //     try {
  //       media.pause();
  //       media.muted = true;
  //       setIsPlaying(false);
  //       setIsMute(true);
  //     } catch (e) {
  //       console.warn(e);
  //     }
  //   }
  // };

  const toggleMute = () => {
    setIsMute(!isMute);
    if (media) media.muted = !isMute;
  };

  if (!mediaUrl || !isVideo) return null;

  if (isMobile) {
    return null;
    // return (
    //   <div style={{ ...wrapper, padding: 0 }}>
    //     <button style={button} onClick={() => playAndUnmute(media)}>
    //       {isPlaying ? <PlayButton /> : <PauseButton />}
    //     </button>
    //   </div>
    // );
  }

  return (
    <div style={wrapper}>
      <button style={button} onClick={toggleMute}>
        {isMute ? <MuteIcon /> : <UnmuteIcon />}
      </button>
    </div>
  );
};

// const PlayButton = ({ color = "#999" } = {}) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 142.448 142.448"
//       style={{
//         width: "50px",
//         enableBackground: "new 0 0 142.448 142.448",
//       }}
//       xmlSpace="preserve"
//     >
//       <path
//         style={{
//           fill: color,
//         }}
//         d="M142.411 68.9C141.216 31.48 110.968 1.233 73.549.038c-20.361-.646-39.41 7.104-53.488 21.639C6.527 35.65-.584 54.071.038 73.549c1.194 37.419 31.442 67.667 68.861 68.861.779.025 1.551.037 2.325.037 19.454 0 37.624-7.698 51.163-21.676 13.534-13.972 20.646-32.394 20.024-51.871zm-30.798 41.436c-10.688 11.035-25.032 17.112-40.389 17.112-.614 0-1.228-.01-1.847-.029-29.532-.943-53.404-24.815-54.348-54.348-.491-15.382 5.122-29.928 15.806-40.958 10.688-11.035 25.032-17.112 40.389-17.112.614 0 1.228.01 1.847.029 29.532.943 53.404 24.815 54.348 54.348.491 15.382-5.123 29.928-15.806 40.958z"
//       />
//       <path
//         style={{
//           fill: color,
//         }}
//         d="M94.585 67.086 63.001 44.44c-3.369-2.416-8.059-.008-8.059 4.138v45.293c0 4.146 4.69 6.554 8.059 4.138l31.583-22.647c2.834-2.031 2.834-6.244.001-8.276z"
//       />
//     </svg>
//   );
// };

// const PauseButton = ({ color = "#999" } = {}) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 271.953 271.953"
//       style={{
//         width: "50px",
//         enableBackground: "new 0 0 271.953 271.953",
//       }}
//       xmlSpace="preserve"
//     >
//       <path
//         style={{
//           fill: color,
//         }}
//         d="M135.977 271.953c75.097 0 135.977-60.879 135.977-135.977S211.074 0 135.977 0 0 60.879 0 135.977s60.879 135.976 135.977 135.976zm0-250.197c62.979 0 114.22 51.241 114.22 114.22s-51.241 114.22-114.22 114.22-114.22-51.241-114.22-114.22 51.235-114.22 114.22-114.22z"
//       />
//       <path
//         style={{
//           fill: color,
//         }}
//         d="M110.707 200.114c7.511 0 13.598-6.086 13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598-7.511 0-13.598 6.086-13.598 13.598v103.342c0 7.512 6.086 13.598 13.598 13.598zM165.097 200.114c7.511 0 13.598-6.086 13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598S151.5 75.663 151.5 83.174v103.342c0 7.512 6.086 13.598 13.597 13.598z"
//       />
//     </svg>
//   );
// };

// const PlayButton = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         width: "80px",
//         height: "80px",
//         borderRadius: "5rem",
//         background: "#fff",
//       }}
//     >
//       <div
//         style={{
//           marginLeft: "10px",
//           width: 0,
//           height: 0,
//           borderStyle: "solid",
//           borderWidth: "25px 0 25px 40px",
//           borderColor: "transparent transparent transparent #007bff",
//         }}
//       ></div>
//     </div>
//   );
// };

const MuteIcon = ({ color = "#999" } = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 75 75"
      stroke={color}
      strokeWidth={5}
    >
      <path
        d="M39 14 22 29H6v19h16l17 15z"
        fill={color}
        strokeLinejoin="round"
      />
      <path d="m49 26 20 24m0-24L49 50" fill="none" strokeLinecap="round" />
    </svg>
  );
};

const UnmuteIcon = ({ color = "#999" } = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 75 75"
    >
      <path
        d="M39.389 13.769 22.235 28.606H6v19.093h15.989l17.4 15.051V13.769z"
        style={{
          stroke: color,
          strokeWidth: 5,
          strokeLinejoin: "round",
          fill: color,
        }}
      />
      <path
        d="M48 27.6A19.5 19.5 0 0 1 48 49m7.1-28.5a30 30 0 0 1 0 35.6M61.6 14a38.8 38.8 0 0 1 0 48.6"
        style={{
          fill: "none",
          stroke: color,
          strokeWidth: 5,
          strokeLinecap: "round",
        }}
      />
    </svg>
  );
};

const wrapper = {
  position: "fixed",
  bottom: 0,
  left: 0,
  display: "grid",
  gridGap: "0.5rem",
  gridAutoFlow: "column",
  margin: ".5rem",
  // background: "#999",
};

// const fullScreenButton = {
//   position: "fixed",
//   top: 0,
//   left: 0,
//   width: "100%",
//   height: "100%",
//   background: "rgba(255,255,255, .2)",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

const button = {
  display: "inline-block",
  padding: ".5rem .5rem",
  borderRadius: "50%",
  border: "5px solid #999",
  margin: 0,
  textDecoration: "none",
  background: "#ffffff",
  color: "#030306",
  fontFamily: "sans-serif",
  fontSize: "1rem",
  lineHeight: 1,
  cursor: "pointer",
  textAlign: "center",
  transition: "background 250ms ease-in-out, transform 150ms ease",
  WebkitAppearance: "none",
  MozAppearance: "none",
};

export default MediaControls;
