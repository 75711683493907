import { PresetDictionary } from "./index";

import { deserialize } from "../utils/serialize";

const getDefaultValues = (qs, searchParams, campaign) => {
  let d = deserialize(qs);

  const nft = searchParams.get("content") || "";
  const nft_fit = searchParams.get("nft_fit") || "CONTAIN";
  const nft_padding = searchParams.get("nft_padding") || 0;
  const backLogo = searchParams.get("backLogo") || "/img/frahm_logo.svg";
  const preset = searchParams.get("preset");

  if (preset && PresetDictionary[preset]) {
    const p = PresetDictionary[preset];
    console.log(`Applying ${p.name} preset`);
    d = { ...d, ...p.value };
  }

  // switch (preset) {
  //   case "1":
  //     d = { ...d, ...Hector };
  //     break;
  //   case "2":
  //     d = { ...d, ...Pandora };
  //     break;
  //   case "3":
  //     d = { ...d, ...Agamemnon };
  //     break;
  //   case "4":
  //     d = { ...d, ...Medusa };
  //     break;
  //   case "5":
  //     d = { ...d, ...Hades };
  //     break;
  //   case "6":
  //     d = { ...d, ...Aurelius };
  //     break;
  //   case "7":
  //     d = { ...d, ...Rusticus };
  //     break;
  //   case "8":
  //     d = { ...d, ...Drusa };
  //     break;
  //   case "9":
  //     d = { ...d, ...Aelia };
  //     break;
  //   case "10":
  //     d = { ...d, ...Cornelius };
  //     break;
  //   case "11":
  //     d = { ...d, ...Ovidia };
  //     break;
  //   default:
  //     break;
  // }

  d.nft = nft;
  d.nft_fit = nft_fit.toUpperCase();
  if (nft_padding) d.nft_padding = nft_padding;
  if (backLogo) d.backLogo = backLogo;

  if (campaign) {
    switch (campaign.toUpperCase()) {
      case "ARCHAN":
        d.nft = "/campaigns/archan/archan_1.mp4";
        break;
      case "ARCHAN-PRANA":
        d.nft = "/campaigns/archan/Prana_ArchanNair.jpg";
        break;
      case "ARCHAN-DAY":
        d.nft = "/campaigns/archan/archan_day.jpg";
        break;
      case "ARCHAN-NIGHT":
        d.nft = "/campaigns/archan/archan_night.jpg";
        break;
      default:
        break;
    }
  }

  console.log(d);

  return d;
};

export default getDefaultValues;
